import React from 'react';
import './Pages.css';

function TrainingInKrab() {
    return (
        <div className="container">
            <div className="col-md-12">
                <header className="header-internal">
                    <h3>Jak szkolimy w Krabie?</h3>
                </header>
                <section className="content">
                    <div className="txtBox">
                        <p>
                            Krab to blisko 60 lat doświadczenia w szkoleniu płetwonurków. Największy i jeden z najstarszych klubów
                            nurkowych w Polsce. Niestety jeden z ostatnich akademickich klubów płetwonurkowych tak licznych dawniej,
                            który przetrwał transformację nie ulegając pokusie kapitalizmu i zachowując akademicki charakter. Tym
                            bardziej cieszy nasza pozycja nr 1 w Polsce pod względem ilości szkolonych płetwonurków wg
                            międzynarodowych standardów{" "}
                            <a href="https://cmas.org" target="_blank" rel="noopener noreferrer">
                                CMAS
                            </a>.
                        </p>
                        <br></br>
                        <p>
                            Jakość naszych szkoleń jest doceniana przez Komisję Działalności Podwodnej ZG PTTK, która powierza nam
                            organizację i prowadzenie Centralnych Kursów Instruktorskich. Posiadamy Certyfikat Jakości wydany przez
                            KDP ZG PTTK. Nasz Klub bierze czynny udział w tworzeniu nowych programów szkoleń, jest autorem nowych
                            specjalizacji w federacji KDP/CMAS.
                        </p>
                        <br></br>
                        <p>
                            Krab to największe zaplecze instruktorskie, żadna baza czy centrum nurkowe nie może poszczycić się taką
                            ilością doświadczonej kadry szkoleniowej.
                        </p>
                        <br></br>
                        <p>
                            Krabowska Szkoła Nurkowa wypracowana przez pokolenia instruktorów kładzie nacisk na jakość i
                            bezpieczeństwo szkoleń. Dużą wagę przykładamy do wstępnego przygotowania kursantów do kursów na wodach
                            otwartych. Nie poddajemy się presji czasów i komercyjnego podejścia do szkoleń. Nie zajmujemy się
                            masowym produkowaniem płetwonurków na kilkudniowych kursach, którzy nie są w stanie czerpać
                            przyjemności z obcowania z podwodnym światem, ani nie są w nim bezpieczni.
                        </p>
                        <div className="images" style={{ textAlign: "center" }}>
                            <img
                                src="https://www.akpkrab.pl/uploads/szkolenie_w_krabie.jpg"
                                alt="Woda to nasz drugi dom :)"
                                title="Woda to nasz drugi dom :)"
                                style={{
                                    width: "100%",
                                    maxWidth: "600px",
                                    height: "auto",
                                    marginTop: "20px",
                                    borderRadius: "8px",
                                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)"
                                }}
                            />
                        </div>
                        <p style={{ textAlign: "center", fontStyle: "italic", marginTop: "10px" }}>
                            Woda to nasz drugi dom... :)
                        </p>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default TrainingInKrab;
