import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './StickyMenu.css';

const StickyMenu = () => {
    const [isSticky, setIsSticky] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [expandedMenu, setExpandedMenu] = useState(null); // Stan rozwinięcia submenu
    const [isMenuOpen, setIsMenuOpen] = useState(false); // Stan otwartego głównego menu

    // Static menu data
    const pagesData = [
        {
            id: 1,
            title: "O nas",
            link: "o-nas",
            subpages: [
                { id: 11, title: "Kim jesteśmy", link: "o-nas/kim-jestesmy" },
                { id: 12, title: "Historia kraba", link: "o-nas/historia-kraba" },
                { id: 13, title: "Instruktorzy", link: "o-nas/instruktorzy" },
                { id: 14, title: "Statut klubu", link: "o-nas/statut-klubu" },
                { id: 15, title: "Zarząd i rada klubu", link: "o-nas/zarzad-i-rada-klubu" },
                { id: 16, title: "Lekarze klubowi", link: "o-nas/lekarze-klubowi" },
                { id: 17, title: "Kalendarium Krabowe '24", link: "o-nas/kalendarium-krabowe" }
            ]
        },
        {
            id: 2,
            title: "Rekrutacja",
            link: "rekrutacja",
            subpages: [
                { id: 21, title: "Dołącz do nas!", link: "rekrutacja/dolacz-do-nas" },
                { id: 22, title: "Kurs P1", link: "rekrutacja/kurs-p1" },
                { id: 23, title: "Obóz Szkoleniowy", link: "rekrutacja/oboz-elba" }
            ]
        },
        {
            id: 3,
            title: "Szkolenia",
            link: "szkolenia",
            subpages: [
                { id: 31, title: "Jak szkolimy w krabie", link: "szkolenia/jak-szkolimy-w-krabie" },
                { id: 32, title: "Treningi basenowe", link: "szkolenia/treningi-basenowe" },
                { id: 33, title: "Harmonogram treningów basenowych", link: "szkolenia/harmonogram-treningow-basenowych" },
                { id: 34, title: "Opis kursów nurkowych", link: "szkolenia/opis-kursow-nurkowych" },
                { id: 35, title: "Harmonogram kursów nurkowych", link: "szkolenia/harmonogram-kursow-nurkowych" },
                { id: 36, title: "Sekcja freedivingu", link: "szkolenia/sekcja-freedivingu" }
            ]
        }
    ];

    const handleScroll = () => {
        setIsSticky(window.scrollY > 100);
    };

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen); // Zmienia stan otwartego menu
    };

    const toggleSubmenu = (id) => {
        // Zmienia stan rozwinięcia submenu
        setExpandedMenu(expandedMenu === id ? null : id);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);
        handleResize(); // Inicjalizacja stanu przy starcie
        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const renderSubpages = (id, subpages) => (
        <div className="subpages">
            {subpages.map((subpage) => (
                <div>
                    <div className="subpages-border"></div>
                    <div onClick={() => {
                        toggleMenu(id);
                        setTimeout(() => { window.location.reload(); }, 0);
                    }} key={subpage.id}>
                        <Link to={`/${subpage.link}`} onClick={() => {
                            toggleSubmenu(id);
                            setTimeout(() => { setTimeout(() => { window.location.reload(); }, 0);; }, 0);
                        }}
                            className="subpage-link">{subpage.title}</Link>
                    </div>
                </div>
            ))}
        </div>
    );

    return (
        <div className={`sticky-menu ${isSticky ? 'sticky-active' : 'transparent'}`}>
            {isMobile ? (
                <div className="dropdown-menu-container">
                    <div className="dropdown-menu-container-cont">
                        <Link to="/" onClick={() => {
                            toggleSubmenu(111);
                        }}>
                            <img className="menu-img" src="https://www.akpkrab.pl/uploads/favicon.png" alt="Logo kraba" />
                        </Link>
                    </div>
                    <div className="dropdown-menu">
                        <button className="menu-button" onClick={toggleMenu}>
                            {isMenuOpen ? 'Zamknij' : 'Menu'}
                        </button>
                        <div className={`dropdown-content ${isMenuOpen ? 'open' : ''}`}>
                            {pagesData.map((page) => (
                                <div key={page.id} className="menu-item">
                                    <button
                                        className="main-item"
                                        onClick={() => toggleSubmenu(page.id)}
                                    >
                                        {page.title}
                                    </button>
                                    {page.subpages && expandedMenu === page.id && renderSubpages(page.id, page.subpages)}
                                </div>
                            ))}
                            <div key="cennik" className="menu-item" onClick={() => toggleSubmenu(111)}>
                                <Link to="/cennik" className="main-item" onClick={() => {
                                    toggleMenu();
                                    setTimeout(() => { window.location.reload(); }, 0);
                                }}>
                                    Cennik
                                </Link>
                            </div>
                            <a href="/rezerwacja" className="main-item">
                                Rezerwacja sprzętu
                            </a>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="menu-content">
                    <Link to="/" onClick={() => {
                        toggleSubmenu(111);
                    }}>
                        <img className="menu-img" src="https://www.akpkrab.pl/uploads/favicon.png" alt="Logo kraba" />
                    </Link>
                    {pagesData.map((page) => (
                        <div key={page.id} className={`menu-item ${expandedMenu === page.id ? 'open' : ''}`} onMouseEnter={() => toggleSubmenu(page.id)} onMouseLeave={() => toggleSubmenu(page.id)}>
                            <button className="main-item">
                                {page.title}
                            </button>
                            {page.subpages && expandedMenu === page.id && renderSubpages(page.id, page.subpages)}
                        </div>
                    ))}
                    <div onClick={() => toggleSubmenu(111)}>
                        <Link to="/cennik" className="main-item" onClick={() => {
                            toggleMenu();
                            setTimeout(() => { window.location.reload(); }, 0);
                        }}>
                            Cennik
                        </Link>
                    </div>
                    <div>
                        <a href="/rezerwacja" className="main-item">
                            Rezerwacja sprzętu
                        </a>
                    </div>
                </div>
            )}
        </div>
    );
};

export default StickyMenu;
