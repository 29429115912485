import React from 'react';
import './Pages.css';

function RecrutationCourseP1() {
    return (
        <div className="container">
            <div className="col-md-12">
                <header className="header-internal">
                    <h3>Kurs P1</h3>
                </header>
                <section className="content recrutation-content">
                    <div className="txtBox">
                        <p>
                            <strong>Ahoj, bardzo nam miło, że planujesz kurs nurkowy!</strong>
                        </p>
                        <br></br>
                        <p>
                            Przygotowaliśmy dla Ciebie krótki opis jak wygląda organizacja takiego kursu w wydaniu weekendowym.
                            Postaraliśmy się opisać go najlepiej jak potrafimy! W razie pytań pisz do nas śmiało :)
                        </p>
                        <br></br>
                        <p>
                            <strong>O tym jakie uprawnienia uzyskasz po kursie, jakie są warunki uczestnictwa i program szkolenia dowiesz się stąd:</strong>{" "}
                            <a href="https://akpkrab.pl/course/p1" target="_blank" rel="noopener noreferrer">
                                Kurs P1 w Krabie
                            </a>
                        </p>
                        <br></br>
                        <p>
                            <strong>Jeżeli wiesz już, że chcesz wziąć udział w kursie, to zapraszamy do zapisów pod tym linkiem:</strong>{" "}
                            <a
                                href="https://docs.google.com/forms/d/e/1FAIpQLSfM6D35HZbHp2R7u0RaUexxZFKa1Tbd3jYF7SscH_0cwBYpLQ/viewform"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Zapisy na kurs P1
                            </a>
                        </p>
                        <br></br>
                        <p>
                            Kurs obejmuje 2 kolejne weekendy szkoleniowe. Jeżeli zadeklarowałaś/eś udział w kursie poprzez formularz zapisów lub osobiście w Klubie, przekazujemy kontakt do Ciebie instruktorowi prowadzącemu kurs. Instruktor kontaktuje się z Tobą i umawia na pierwsze spotkanie, które zazwyczaj ma miejsce w piątek, w przeddzień rozpoczęcia kursu w siedzibie Klubu gdzie też poznajesz swoich kolegów i koleżanki z kursu :)
                        </p>
                        <br></br>
                        <p>
                            Spotykacie się razem z instruktorem w Klubie i pobieracie sprzęt z naszego magazynu (ul. Reymonta 21a). Nie martwcie się, nasi sprzętowcy dobiorą dla Ciebie odpowiedni skafander i wydadzą sprzęt, a w biurze ogarniemy wszelkie sprawy formalne (regulaminy, oświadczenia o stanie zdrowia, zgody itp.).
                        </p>
                        <br></br>
                        <p>
                            Lekki sprzęt typu skafander i automat zabierasz ze sobą do domu i oddajesz go dopiero po zakończeniu kursu. Ciężki sprzęt, jak butle, wymieniacie/nabijacie po każdym dniu nurkowym w klubowych sprężarkach.
                        </p>
                        <div className="images">
                            <img
                                src="https://www.akpkrab.pl/uploads/68951788_10156421804705592_4267449006742831104_n.jpg"
                                alt="Koparki w Jaworznie podczas kursu P1"
                                title="Koparki w Jaworznie podczas kursu P1"
                                style={{
                                    width: '100%',
                                    maxWidth: '600px',
                                    height: 'auto',
                                    borderRadius: '8px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                    marginTop: '20px',
                                }}
                            />
                        </div>
                        <p>
                            Następnego dnia o umówionej godzinie spotykasz się razem ze swoim instruktorem oraz grupą kursową prawdopodobnie w Jaworznie na „Koparkach” (Diving Marina „Koparki”, ul. Płetwonurków, 43-602 Jaworzno).
                        </p>
                        <br></br>
                        <p>
                            <strong>Ostatecznie to Wy, wraz z instruktorem wybieracie nurkowisko. Sposób transportu i miejsce nurkowania zależy od Was :)</strong>
                        </p>
                        <br></br>
                        <p>
                            Na nurkowisku omawiacie wraz z instruktorem wszelkie niezbędne aspekty nurkowania, klarujecie sprzęt i po raz pierwszy schodzicie pod wodę! Po nurkowaniach wracacie do Krakowa do Klubu, gdzie w klubowej sprężarce nabijane są butle na dzień następny (lub zabierane już pełne ze sprężarki).
                        </p>
                        <br></br>
                        <p>
                            Plan dnia powtarza się również w niedzielę oraz w następny weekend (tylko sobota i niedziela). Na dzień nurkowy warto zarezerwować czas co najmniej do 17, dużo się dzieje i trochę to trwa, ale uwierz, że nie zorientujesz się, kiedy uciekł Ci dzień :)
                        </p>
                        <br></br>
                        <p>
                            <strong>Kurs zakończony jest obowiązkowym egzaminem teoretycznym.</strong>
                        </p>
                        <br></br>
                        <p>
                            Przygotowaniem do egzaminu są wykłady prowadzone przez instruktora w trakcie kursu.
                            Bardzo częstą praktyką są wykłady pomiędzy nurkowaniami, ale możecie także spotkać się z prowadzącym w trakcie tygodnia w umówionym miejscu. Wszystko zależy od Waszych ustaleń z instruktorem.
                        </p>
                        <br></br>
                        <p>
                            Po zakończeniu kursu zwracasz wysuszony sprzęt z powrotem do Klubu, a instruktor składa Twój wniosek o certyfikat, wypełnia wszystkie dokumenty, symbolicznie Cię chrzci i mamy nowego Kraba :) Od tej pory możesz już nurkować (upoważnia Cię do tego książka nurkowa z opisem przebiegu szkolenia), a certyfikat przyjdzie pocztą na podany adres.
                        </p>
                        <br></br>
                        <p>
                            Z ważniejszych informacji: często grupa nurkowa skrzykuje się i jedzie razem do Jaworzna (lub z instruktorem) – kwestia do dogadania. Koszty dojazdu we własnym zakresie. Ponadto wejściówka na Koparki w tym sezonie wynosi 50 zł per osoba, koszt ten również należy pokryć samemu.
                        </p>
                        <br></br>
                        <p>
                            <strong>W ramach kursu zapewniamy Ci:</strong>
                        </p>
                        <br></br>
                        <ul>
                            <li>
                                komplet sprzętu (piankę lub półsuchy skafander, kaptur, buty, rękawiczki, płetwy, komputer, butle, balast – nasz sprzętowiec wszystko dla Ciebie dobierze z czeluści klubowego magazynu), zalecamy jednak żeby wyposażyć się w sprzęt ABC - maskę, płetwy i fajkę. O tym jak dobrać maskę możesz dowiedzieć się z tego{" "}
                                <a
                                    href="https://www.youtube.com/watch?v=pxiEsqqyyjM"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    filmiku
                                </a>{" "}
                                nakręconego przez naszego viceprezesa ds. technicznych lub odwiedzić nas w Klubie i zasięgnąć porady :) Taki sprzęt warto mieć, ale w przypadku braku zawsze możemy go pożyczyć :)
                            </li>
                            <li>
                                książkę niezbędną do zgłębienia teorii nurkowania (wielu z nas uczyło się z niej również przed egzaminami na wyższe stopnie nurkowe),
                            </li>
                            <li>dostęp do platformy elerningowej,</li>
                            <li>
                                certyfikat (poświadczający uzyskane uprawnienia, przypomina dowód osobisty lub kartę kredytową),
                            </li>
                            <li>
                                logbook (specjalna książeczka do prowadzenia rejestru wykonanych nurkowań),
                            </li>
                            <li>książeczkę nurkową zawierającą przebieg Twoich kursów,</li>
                            <li>ubezpieczenie,</li>
                            <li>opłaconą składkę członkowską do końca roku kalendarzowego!</li>
                        </ul>
                        <div className="images">
                            <img
                                src="https://www.akpkrab.pl/uploads/13147756_10153565373570592_3794192609770243129_o.jpg"
                                alt="Sprzęt przygotowany na pierwsze nurkowanie!"
                                title="Sprzęt przygotowany na pierwsze nurkowanie!"
                                style={{
                                    width: '100%',
                                    maxWidth: '600px',
                                    height: 'auto',
                                    borderRadius: '8px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                    marginTop: '20px',
                                }}
                            />
                        </div>
                        <p>
                            <strong>Warunki uczestnictwa w kursie:</strong>
                        </p>
                        <br></br>
                        <ul>
                            <li>
                                Umiejętność pływania (nie mówimy o umiejętnościach olimpijskich, po prostu dobrze byłoby nie panikować na widok wody), ale uwierz nam na słowo, że wśród naszych nurków jest kilka osób nie umiejących pływać w myśl zasady: do nurkowania nie trzeba umieć pływać, po coś w końcu masz butle pod wodą
                            </li>
                            <li>
                                Oświadczenie kursanta o braku przeciwwskazań do rozpoczęcia kursu płetwonurkowania (ale polecamy zrobić dla własnego spokoju serię badań opisanych na naszej stronie:{" "}
                                <a
                                    href="https://akpkrab.pl/o-nas/lekarze-klubowi"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Lekarze Klubowi
                                </a>{" "}
                                i skonsultować się naszym lekarzem klubowym wymienionym na stronie lub z lekarzem sportowym, czy wszystko jest OK).
                            </li>
                        </ul>
                        <br></br>
                        <p>
                            Koszt kursu to <strong>1700 zł</strong>, cena która zawiera:
                        </p>
                        <br></br>
                        <ul>
                            <li>certyfikat wraz z logbookiem</li>
                            <li>podręcznik do nauki P1</li>
                            <li>dostęp do platformy e-learningowej</li>
                            <li>cały potrzebny sprzęt na czas trwania kursu</li>
                            <li>składkę członkowską do końca roku kalendarzowego</li>
                        </ul>
                        <p>
                            Do ceny należy doliczyć koszt wejściówek na akwen nurkowy oraz transport
                        </p>
                        <p>
                            Jeżeli masz jakiekolwiek pytania, pisz do nas na nasz adres mailowy:{" "}
                            <a href="mailto:krab@agh.edu.pl">krab@agh.edu.pl</a> lub znajdź nas na Facebooku!
                        </p>
                        <br></br>
                        <p>
                            <strong>Do zobaczenia w wodzie :)</strong>
                        </p>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default RecrutationCourseP1;