import React from 'react';
import './Pages.css';

function TrainingDivingCourseSchedule() {
    return (
        <div className="container">
            <div className="col-md-12">
                <header className="header-internal">
                    <h3>Harmonogram kursów nurkowych</h3>
                </header>
                <section className="content">
                    <div className="txtBox">
                        <p>
                            Poniżej znajdziecie aktualny harmonogram szkoleń prowadzonych w naszym klubie w tym sezonie
                            szkoleniowym. Istnieje również możliwość umówienia indywidualnych terminów - wymagana liczba
                            chętnych na taki kurs to minimum 4 osoby.
                        </p>
                        <p>
                            <strong>
                                Nie znalazłeś/znalazłaś interesującego Cię kursu/terminu? Nie ma problemu!{' '}
                                <a href="http://krab.agh.edu.pl/kontakt" target="_blank" rel="noopener noreferrer">
                                    Skontaktuj się z nami w tej sprawie.
                                </a>
                            </strong>
                        </p>
                        <p>
                            Formularz zapisu na kurs P1 (2024 rok) znajdziecie tutaj:{' '}
                            <strong>
                                <a
                                    href="https://docs.google.com/forms/d/e/1FAIpQLSfM6D35HZbHp2R7u0RaUexxZFKa1Tbd3jYF7SscH_0cwBYpLQ/viewform?usp=sf_link"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Formularz zapisów kurs P1
                                </a>
                            </strong>
                        </p>
                        <p>
                            Zapisy na pozostałe kursy zrealizujecie poprzez następujący formularz:{' '}
                            <strong>
                                <a
                                    href="https://docs.google.com/forms/d/e/1FAIpQLScGe0O6mvxPokYjyhbD8rnQ-BtvzLOBp8S9QrnNASaeanU1VA/viewform?usp=sf_link"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    Formularz zapisów - pozostałe kursy
                                </a>
                            </strong>
                        </p>
                        <br></br>
                        <p>
                            <em>Kliknij nazwę kursu aby zobaczyć jego szczegółowy opis.</em>
                        </p>
                        <br></br>

                        {renderCourseSection('Płetwonurek KDP CMAS P1', 'https://akpkrab.pl/course/p1', [
                            { start: '10.05.2024', deadline: '03.05.2024' },
                            { start: '31.05.2024 - ELBA', deadline: '07.04.2024' },
                            { start: '21.06.2024', deadline: '14.06.2024' },
                            { start: '05.07.2024', deadline: '28.06.2024' },
                            { start: '19.07.2024', deadline: '12.07.2024' },
                            { start: '02.08.2024', deadline: '26.07.2024' },
                            { start: '16.08.2024', deadline: '09.08.2024' },
                            { start: '30.08.2024', deadline: '23.08.2024' },
                            { start: '13.09.2024', deadline: '06.09.2024' },
                            { start: '27.09.2024', deadline: '20.09.2024' },
                            { start: '11.10.2024', deadline: '04.10.2024' },
                        ])}

                        {renderCourseSection('Płetwonurek KDP CMAS PNO', 'https://akpkrab.pl/course/pno', [
                            { start: '10.05.2024', deadline: '03.05.2024' },
                            { start: '31.05.2024 - ELBA', deadline: '07.04.2024' },
                            { start: '21.06.2024', deadline: '14.06.2024' },
                            { start: '12.07.2024', deadline: '05.07.2024' },
                            { start: '26.07.2024', deadline: '19.07.2024' },
                            { start: '09.08.2024', deadline: '02.08.2024' },
                            { start: '23.08.2024', deadline: '16.08.2024' },
                            { start: '06.09.2024', deadline: '30.08.2024' },
                            { start: '20.09.2024', deadline: '13.09.2024' },
                            { start: '04.10.2024', deadline: '27.09.2024' },
                        ])}

                        {renderCourseSection('Płetwonurek KDP CMAS PNA', 'https://akpkrab.pl/course/pna', [
                            { start: '10.05.2024', deadline: '03.05.2024' },
                            { start: '31.05.2024 - ELBA', deadline: '07.04.2024' },
                            { start: '21.06.2024', deadline: '14.06.2024' },
                            { start: '12.07.2024', deadline: '05.07.2024' },
                            { start: '26.07.2024', deadline: '19.07.2024' },
                            { start: '09.08.2024', deadline: '02.08.2024' },
                            { start: '23.08.2024', deadline: '16.08.2024' },
                            { start: '06.09.2024', deadline: '30.08.2024' },
                            { start: '20.09.2024', deadline: '13.09.2024' },
                            { start: '04.10.2024', deadline: '27.09.2024' },
                        ])}

                        {renderCourseSection('Płetwonurek KDP CMAS PE', 'https://akpkrab.pl/course/pe', [
                            { start: '17.05.2024', deadline: '10.05.2024' },
                            { start: '31.05.2024 - ELBA', deadline: '07.04.2024' },
                            { start: '28.06.2024', deadline: '21.06.2024' },
                            { start: '12.07.2024', deadline: '05.07.2024' },
                            { start: '26.07.2024', deadline: '19.07.2024' },
                            { start: '02.08.2024', deadline: '26.07.2024' },
                            { start: '16.08.2024', deadline: '09.08.2024' },
                            { start: '30.08.2024', deadline: '23.08.2024' },
                            { start: '13.09.2024', deadline: '06.09.2024' },
                            { start: '04.10.2024', deadline: '27.09.2024' },
                        ])}

                        {renderCourseSection('Płetwonurek KDP CMAS P2', 'https://akpkrab.pl/course/p2', [
                            { start: '31.05.2024 - ELBA', deadline: '07.04.2024' },
                        ])}

                        {renderCourseSection('Płetwonurek KDP CMAS PN1', 'https://akpkrab.pl/course/pn1', [
                            { start: '10.05.2024', deadline: '03.05.2024' },
                            { start: '31.05.2024 - ELBA', deadline: '07.04.2024' },
                            { start: '28.06.2024', deadline: '21.06.2024' },
                            { start: '23.08.2024', deadline: '16.08.2024' },
                            { start: '13.09.2024', deadline: '06.09.2024' },
                            { start: '04.10.2024', deadline: '27.09.2024' },
                        ])}

                        {renderCourseSection('Płetwonurek KDP CMAS PSS', 'https://akpkrab.pl/course/pss', [
                            { start: '10.05.2024', deadline: '03.05.2024' },
                            { start: '31.05.2024 - ELBA', deadline: '07.04.2024' },
                            { start: '28.06.2024', deadline: '21.06.2024' },
                            { start: '19.07.2024', deadline: '12.07.2024' },
                            { start: '23.08.2024', deadline: '16.08.2024' },
                            { start: '13.09.2024', deadline: '06.09.2024' },
                        ])}

                        {renderCourseSection('Płetwonurek KDP CMAS PZB', 'https://akpkrab.pl/course/pzb', [
                            { start: '10.05.2024', deadline: '03.05.2024' },
                            { start: '31.05.2024 - ELBA', deadline: '07.04.2024' },
                            { start: '05.07.2024', deadline: '28.06.2024' },
                            { start: '02.08.2024', deadline: '26.07.2024' },
                            { start: '13.09.2024', deadline: '06.09.2024' },
                            { start: '11.10.2024', deadline: '04.10.2024' },
                        ])}

                        {renderCourseSection('Płetwonurek KDP CMAS PKB', 'https://akpkrab.pl/course/pkb1', [
                            { start: '10.05.2024', deadline: '03.05.2024' },
                            { start: '31.05.2024 - ELBA', deadline: '07.04.2024' },
                            { start: '05.07.2024', deadline: '28.06.2024' },
                            { start: '02.08.2024', deadline: '26.07.2024' },
                            { start: '13.09.2024', deadline: '06.09.2024' },
                            { start: '11.10.2024', deadline: '04.10.2024' },
                        ])}

                        {renderCourseSection('Płetwonurek KDP CMAS PN2', 'https://akpkrab.pl/course/pn2', [
                            { start: '31.05.2024 - ELBA', deadline: '07.04.2024' },
                            { start: '19.07.2024', deadline: '12.07.2024' },
                            { start: '13.09.2024', deadline: '06.09.2024' },
                        ])}

                        {renderCourseSection('Płetwonurek KDP CMAS PN2 w konfiguracji bocznej PN2KB', 'https://akpkrab.pl/course/pn2', [
                            { start: '31.05.2024 - ELBA', deadline: '07.04.2024' },
                            { start: '12.07.2024', deadline: '05.07.2024' },
                            { start: '06.09.2024', deadline: '30.08.2024' },
                        ])}

                        {renderCourseSection('Płetwonurek KDP CMAS PJ1', 'https://akpkrab.pl/course/pj1', [
                            { start: '31.05.2024 - ELBA', deadline: '07.04.2024' },
                        ])}

                        {renderCourseSection('Płetwonurek KDP CMAS PPW', 'https://akpkrab.pl/course/ppw', [
                            { start: '31.05.2024 - ELBA', deadline: '07.04.2024' },
                            { start: '19.07.2024', deadline: '12.07.2024' },
                            { start: '20.09.2024', deadline: '13.09.2024' },
                            { start: '18.10.2024', deadline: '11.10.2024' },
                        ])}

                        {renderCourseSection('Płetwonurek KDP CMAS PEK (płetwonurek ekolog)', 'https://akpkrab.pl/course/pek', [
                            { start: '19.07.2024', deadline: '12.07.2024' },
                            { start: '20.09.2024', deadline: '13.09.2024' },
                        ])}

                        {renderCourseSection('Płetwonurek KDP CMAS P3', 'https://akpkrab.pl/course/p3', [
                            { start: '31.05.2024 - ELBA', deadline: '07.04.2024' },
                        ])}

                        {renderCourseSection('Płetwonurek KDP CMAS PPI (P3i)', 'https://akpkrab.pl/course/ppi', [
                            { start: '31.05.2024 - ELBA', deadline: '07.04.2024' },
                            { start: '05.07.2024', deadline: '28.06.2024' },
                            { start: '02.08.2024', deadline: '26.07.2024' },
                            { start: '30.08.2024', deadline: '23.08.2024' },
                            { start: '04.10.2024', deadline: '27.09.2024' },
                        ])}

                        {renderCourseSection('Płetwonurek KDP CMAS PPK (Płetwonurek Działań Proobronnych i Kryzysowych)', 'https://akpkrab.pl/course/ppk', [
                            { start: '19.07.2024', deadline: '12.07.2024' },
                            { start: '20.09.2024', deadline: '13.09.2024' },
                            { start: '18.10.2024', deadline: '11.10.2024' },
                        ])}
                    </div>
                </section>
            </div>
        </div>
    );
}

function renderCourseSection(title, link, data) {
    return (
        <>
            <h4>
                <a href={link} target="_blank" rel="noopener noreferrer">
                    {title}
                </a>
            </h4>
            <table className="table-bordered-sec">
                <thead>
                    <tr>
                        <th>Data rozpoczęcia kursu</th>
                        <th>Ostateczny termin zapisów</th>
                    </tr>
                </thead>
                <tbody>
                    {data.map((item, index) => (
                        <tr key={index}>
                            <td>{item.start}</td>
                            <td>{item.deadline}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </>
    );
}

export default TrainingDivingCourseSchedule;
