import React from 'react';
import './Pages.css';

function RecrutationCampElba() {
    return (
        <div className="container">
            <div className="col-md-12">
                <header className="header-internal">
                    <h3>Obóz - Elba</h3>
                </header>
                <section className="content">
                    <div className="txtBox">
                        {/* Nagłówek główny */}
                        <h2 style={{ textAlign: 'center', fontSize: '1.8em' }}>
                            Morski Obóz Szkoleniowy na Elbie we Włoszech
                        </h2>

                        {/* Pełna treść */}
                        <p>
                            Jeżeli zastanawiałeś/-aś się co to takiego ten Morski Obóz Szkoleniowy na Elbie, o którym na
                            każdym kroku opowiadamy, ten artykuł jest dla Ciebie. Poniżej znajdziesz garść informacji
                            jak ten wyjazd wygląda, jak się na niego przygotować, co zastaniesz na miejscu i jakich
                            atrakcji możesz się spodziewać.
                        </p>
                        <br></br>
                        <p>
                            W środę, zwyczajowo o godznie 17:00 spotykamy się na Parkingu TS Wisła (ul Reymonta 22).
                            Wyjazd zaplanowany jest na godzinę 18:00. Wyjeżdżamy punktualnie, ponieważ następnego dnia
                            MUSIMY zdążyć na prom na Elbę. Jeśli chodzi o bagaże, bardzo prosimy o rozsądne pakowanie -
                            najlepiej do miękkich plecaków lub toreb, tak aby dla wszystkich starczyło miejsca w lukach
                            bagażowych.
                        </p>
                        <br></br>
                        <p>
                            Wasze bagaże można podzielić na dwie kategorie: prywatne rzeczy (ubrania, jedzenie) oraz
                            osobisty sprzęt nurkowy. Jeżeli jedziecie na obóz aby wziąć udział w kursie, poprosimy Was o
                            zgłoszenie się w ostatnim tygodniu przed wyjazdem do klubowego magazynu, gdzie zostanie dla
                            Was dobrany sprzęt nurkowy: pianka, komputer, automat itp. Wszystko zapakowane zostanie w
                            ikejkę:
                        </p>

                        <div className="images">
                            <img
                                src="https://www.akpkrab.pl/uploads/ikejka.png"
                                alt="Ikejka"
                                style={{
                                    width: '100%',
                                    maxWidth: '600px',
                                    height: 'auto',
                                    borderRadius: '8px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                    marginTop: '20px',
                                }}
                            />
                        </div>

                        <p>
                            która na Elbę dotrze ciężarówką razem z innym naszym ciężkim sprzętem takim jak butle,
                            balast, sprężarki, pontony. Ciężarówka wyjedzie z Polski kilka dni przed autobusem, o czym
                            zostaniecie powiadomieni w oddzielnej korespondencji. Wówczas przyniesiecie ikejki i razem
                            załadujemy potrzebny sprzęt z magazynu tak, aby niczego nam na Elbie nie zabrakło. Jeżeli
                            macie swój prywatny sprzęt nurkowy, to także prosimy o przyniesienie go wcześniej i
                            zapakowanie na ciężarówkę:
                        </p>

                        <div className="images">
                            <img
                                src="https://www.akpkrab.pl/uploads/tir1.png"
                                alt="Ciężarówka z nurkowym sprzętem"
                                style={{
                                    width: '100%',
                                    maxWidth: '600px',
                                    height: 'auto',
                                    borderRadius: '8px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                    marginTop: '20px',
                                }}
                            />
                            <img
                                src="https://www.akpkrab.pl/uploads/tir2.png"
                                alt="Ciężarówka z nurkowym sprzętem"
                                style={{
                                    width: '100%',
                                    maxWidth: '600px',
                                    height: 'auto',
                                    borderRadius: '8px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                    marginTop: '20px',
                                }}
                            />
                        </div>

                        <p>
                            Do autobusu zabieracie z kolei swoje osobiste bagaże oraz jedzenie, ale apelujemy o zdrowy
                            rozsądek. Na wyspie jest sklep, restauracja, pizzeria. Na pewno będzie można na miejscu
                            zakupić najpotrzebniejsze artykuły.
                        </p>
                        <br></br>
                        <h4>Zakwaterowanie i dzień obozowy</h4>
                        <br></br>
                        <p>
                            Do Nisportino dotrzemy następnego dnia po wyjeździe, w czwartek po południu, wówczas będzie
                            czas za zakwaterowanie Was w domkach/pokojach oraz rozpakowywanie autobusu. Propozycję
                            zakwaterowania przedstawimy Wam wcześniej. Wieczorem spotkamy się na pierwszym apelu, gdzie
                            omówimy sobie jak będą wyglądały następne dni obozowe.
                        </p>
                        <br></br>
                        <p>
                            Śpimy w 4-, 5- lub 6-cio osobowych domkach, w których macie w pełni wyposażoną kuchnię (no,
                            może nie w 100% pełną - jeśli preferujecie duże kubki na kawę/herbatę to polecamy je zabrać
                            z Polski :)). Wyposażenie kuchni pozwala jednak na przygotowywanie posiłków (możecie
                            zaopatrzyć się w składniki w Polsce, albo robić zakupy w sklepiku w pobliżu campingu), są
                            dostępne patelnie, talerze, sztućce, kawiarka.
                        </p>
                        <br></br>
                        <p>
                            Jeśli ktoś jest zmarzluchem to polecamy również zabrać śpiwór lub dodatkowy koc/kołdrę.
                            Włochy są ciepłym krajem, ale niektóre noce w nadmorskich miejscowościach mogą być chłodne.
                            W domkach mamy do dyspozycji koce, ręczniki, ścierki itp. Pamiętajcie, aby zabrać ze sobą
                            ręczniki plażowe.
                        </p>
                        <br></br>
                        <h4>Plan dnia obozowego</h4>
                        <br></br>
                        <p>
                            O 7:30 zaczynamy rozbieżką i rozpływką - trochę pobiegamy, trochę popływamy a przede
                            wszystkim rozgrzejemy się przed pierwszym dniem szkoleniowym. Jest to obowiązkowy punkt dnia
                            dla kursantów, rekreacja nie musi uczestniczyć w porannych ćwiczeniach, natomiast gorąco ją
                            do tego zachęcamy! Potem przychodzi czas na śniadanie, a następnie spotykamy się na plaży o
                            godzinie 9:00 na porannej odprawie - tam przekazujemy wszelkie informacje dot. danego dnia
                            obozowego: plan kursów, dodatkowych wypłynięć dla osób nie uczestniczących w kursach,
                            dodatkowych wykładów, zgubionego sprzętu itp.
                        </p>
                        <br></br>
                        <p>
                            Po odprawie spotykamy się ze swoim instruktorem i grupą nurkową, omawiamy co trzeba, z
                            magazynu pobieramy potrzebny sprzęt i ruszamy na pierwsze tego dnia nurkowanie. Następnie
                            czeka Was przerwa obiadowa, chwila relaksu i po południu kolejne nurkowania. Po kolacji
                            spotykamy się na odprawie wieczornej. Często pomiędzy nurkowaniami lub po kolacji spotykać
                            się będziecie ze swoim instruktorem na wykładach teoretycznych, ale to akurat dogadacie już
                            bezpośrednio z Waszym guru. I tak przez 5 dni.
                        </p>
                        <br></br>
                        <p>
                            Pamiętajcie, że odpowiadacie za sprzęt podczas kursu, suszymy go w ogródku domku w którym
                            mieszkamy, lepiej żeby nie leżał rozrzucony po całym ośrodku.
                        </p>
                        <br></br>
                        <h4>Możliwość dodatkowych kursów</h4>
                        <br></br>
                        <p>
                            Informujemy, że po realizacji kursu P1 trwającego od piątku do wtorku włącznie możecie skusić
                            się również na kurs na płetwonurka nocnego PNO (realizowanego w nocy z wtorku na środę) oraz
                            kurs na płetwonurka nawigatora PNA (który odbędzie się w środę) – gorąco Was do tego
                            zachęcamy, do Polski wówczas wrócicie z kompletem uprawnień pozwalającym Wam na jeszcze
                            bardziej złożoną eksplorację podwodnego świata.
                        </p>
                        <br></br>
                        <p>
                            Dla pozostałych kursantów i rekreacji także będziemy mieli propozycję dodatkowych kursów,
                            ale to zostanie już przedstawione w trakcie obozu.
                        </p>
                        <br></br>
                        <h4>Chrzest i odpowiedzialność za sprzęt</h4>
                        <br></br>
                        <p>
                            Po wykonaniu wszystkich planowanych nurkowaniach nadchodzi czas na Chrzest “Pe-zerówek”.
                            Lojalnie uprzedzamy, że łatwo nie będzie, ale na pewno będzie warto! Jest taki przesąd, że
                            nurek nieochrzczony przynosi pecha swoim partnerom nurkowym, więc mimo krążących legend
                            warto się odważyć i dać się ochrzcić. Ktoś kiedyś powiedział, że choć nie chce się drugi raz
                            tego przechodzić, to jednak wspomina się te noc wspaniale i nie żałuje się, że się wzięło
                            udział w tym podniosłym wydarzeniu!
                        </p>
                        <br></br>
                        <p>
                            Na grupach nurkowych spoczywa jeszcze jedno ważne zadanie: każda grupa kursowa musi przez
                            jedną noc na obozie zgłosić się do kierownika magazynu obozowego na wachtę. Jak to wygląda?
                            Po zajeciach wieczornych z Waszym instruktorem, Wasza grupa pojawia się w magazynie o 22:00
                            i do godz. 6:00 musi pełnić wartę i pilnować naszego dobytku klubowego. Zazwyczaj grupa
                            nurkowa dzieli się na dwie części i każda pilnuje po pół nocy. Ale zaraz, zaraz... będziecie
                            mieć z tego realną korzyść: po wachcie taka grupa nurkowa jest zwolniona z porannej
                            rozpływki i rozbieżki ;)
                        </p>
                        <br></br>
                        <h4>Pakowanie na wyjazd</h4>
                        <br></br>
                        <p>
                            W linku poniżej znajdziecie ściągę dotyczącą pakowania na wyjazdy wszelakie, ale pamiętajcie
                            o niezbędnym ekwipunku, który obejmuje:
                        </p>
                        <br></br>
                        <ul>
                            <li>ABC, czyli maskę, płetwy i fajkę</li>
                            <li>Osobisty ekwipunek nurkowy</li>
                            <li>
                                Stroje pływackie i dodatkowo jeden strój kąpielowy przeznaczony na chrzest, czyli taki,
                                którego nie będzie Wam żal wyrzucić
                            </li>
                            <li>Klapki, czapki i ręczniki plażowe, krem z filtrem, coś na komary</li>
                            <li>Tradycyjne ciepłe czapki chroniące po nurkowaniu uszy i zatoki</li>
                            <li>Strój sportowy na obowiązkowe poranne przebieżki</li>
                            <li>
                                Leki, które zażywacie regularnie; dodatkowo jakieś krople do nosa i ucha oraz Ibuprom
                                Zatoki na nurkowe dolegliwości
                            </li>
                            <li>Jedzenie i prowiant na drogę</li>
                            <li>
                                ZDJĘCIA DO KSIĄŻECZEK, warto też mieć kopię elektroniczną na poczcie (min 276x354
                                pikseli)
                            </li>
                        </ul>
                        <br></br>
                        <h4>Ważne dokumenty</h4>
                        <br></br>
                        <ul>
                            <li>Karta EKUZ</li>
                            <li>
                                Zaświadczenie o braku przeciwwskazań do nurkowania wystawione przez lekarza – będzie
                                sprawdzane przez instruktorów przed rozpoczęciem szkolenia
                            </li>
                            <li>Ubezpieczenie nurkowe obejmujące nurkowanie na min. 20 m</li>
                        </ul>
                        <br></br>
                        <h4>Materiały wideo</h4>
                        <br></br>
                        <p>
                            W trosce o bezpieczeństwo przypominamy o konieczności dbania o powierzony ekwipunek, na
                            poniższych filmikach Tomek pokrótce opowiada jak to zrobić. Instruktorzy będą zwracać Wam na
                            to uwagę, a przed czujnym okiem magazynierów nic się nie ukryje!
                        </p>
                        <br></br>
                        <ul>
                            <li>
                                <a href="https://www.youtube.com/watch?v=a_d6ztKN8jo" target="_blank" rel="noreferrer">
                                    Jak skutecznie zakładać skafander neoprenowy
                                </a>
                            </li>
                            <li>
                                <a href="https://www.youtube.com/watch?v=ZlDIiAxNBq8" target="_blank" rel="noreferrer">
                                    Jak szybko i sprawnie zamocować KRW do butli nurkowej
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.youtube.com/watch?v=eeN6fjIarXo&t=1s&ab_channel=NurkowaGalaktyka"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    Skafander Półsuchy, ubiór, konserwacja, suszenie
                                </a>
                            </li>
                        </ul>
                        {/* Kalendarium */}
                        <br></br>
                        <h4>Kalendarium Obóz Elba 2024:</h4>
                        <br></br>
                        <table className="calendar-table">
                            <thead>
                                <tr>
                                    <th style={{ textAlign: 'center' }}>Dzień</th>
                                    <th >Opis</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td style={{ textAlign: 'center' }}>Środa</td>
                                    <td>Zbiórka o 17:00, wyjazd o 18:00</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'center' }}>Czwartek</td>
                                    <td>Przesiadka na prom, dotarcie do Nisporto</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'center' }}>Piątek</td>
                                    <td>Pierwszy dzień obozu</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'center' }}>Sobota</td>
                                    <td>Drugi dzień obozu</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'center' }}>Niedziela</td>
                                    <td>Trzeci dzień obozu</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'center' }}>Poniedziałek</td>
                                    <td>Czwarty dzień obozu</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'center' }}>Wtorek</td>
                                    <td>Możliwość kursu PNO</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'center' }}>Środa</td>
                                    <td>Możliwość kursu PNA</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'center' }}>Czwartek</td>
                                    <td>Chrzest nurków</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'center' }}>Piątek</td>
                                    <td>Pakowanie sprzętu</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'center' }}>Sobota</td>
                                    <td>Wyjazd z Nisporto</td>
                                </tr>
                                <tr>
                                    <td style={{ textAlign: 'center' }}>Niedziela</td>
                                    <td>Powrót do Krakowa</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default RecrutationCampElba;