import React from 'react';
import './Pages.css';

function WeWhoWeAre() {
    return (
        <div className="container">
            <header className="header-internal">
                <h3>Kim jesteśmy...</h3>
            </header>

            <section className="content">
                <p className="text">
                    <strong>Akademicki Klub Podwodny Krab AGH</strong> jest jednym z najstarszych, a w chwili obecnej największym klubem nurkowym w Polsce. Od początku swojego istnienia - od roku 1964 - nierozerwalnie związany z krakowską Akademią Górniczo-Hutniczą, “Krab” propaguje i upowszechnia nurkowanie wśród studentów oraz pracowników naukowych wszystkich uczelni, jak również młodzieży szkół średnich oraz osób chcących poznać podwodny świat. Dzięki prężnej działalności klubowej, wysoko wykwalifikowanej kadrze oraz dużej ilości przeprowadzanych kursów „Krab” do dziś znajduje się w czołówce polskiego środowiska nurkowego.
                </p>

                <div className="images">
                    <img src="https://www.akpkrab.pl/uploads/Hvar1.jpg" alt="Kraby podczas wyprawy na chorwacką wyspę Hvar." />
                    <img src="https://www.akpkrab.pl/uploads/Akademicki Klub Podwodny Krab AGH - Nurkowanie Kraków.jpg" alt="Kraby promujące Klub..." />
                    <img src="https://www.akpkrab.pl/uploads/hvar2.jpg" alt="Nurkowanie na wraku spoczywającym w pobliżu chorwackiej wyspy Hvar." />
                </div>

                <p className="text">
                    Wstąpienie do klubu otwiera wrota do pięknego świata niezliczonej ilości barw, pozwala odkryć w sobie pasje oraz przeżyć niezapomnianą przygodę.
                </p>

                <p className="text">
                    O prężności organizacyjnej klubu świadczą liczne wyprawy: „Koral 74″ nad Zatokę Perską, Grecja 1976, Karaiby 1978/79, Cuba-Meksyk 1982, Meksyk 1987, Hiszpania 1988, Egipt i Sudan 1988, Bajkał 1990, 1991, Francja 1990,1991,1993, Sycylia 1992, Hiszpania i Portugalia 1994, Maroko 1995, Hiszpania 2003, Elba 2004, Egipt, Hvar, Krym 2005, Egipt, Sycylia 2006, Egipt, Murter, Krym 2007, Egipt, Murter 2008, Egipt, Hvar 2009, Vis 2010, Korcula 2011, Murter 2012, Sharm El Sheikh 2013, weekendowe wyjazdy na wraki Bałtyku, klubowe nurkowania w pobliskich akwenach.
                </p>

                <p className="text">
                    Szkolenia na wszystkie stopnie nurkowe, odbywające się pod okiem wyspecjalizowanej kadry instruktorskiej pozwalają na doskonałe i bezstresowe opanowanie sztuki nurkowania. Doskonała jakość szkolenia w "Krabie" została doceniona przez Komisję Działalności Podwodnej ZG PTTK, która przynała klubowi Certyfikat Jakości:
                </p>

                <div className="certificates">
                    <img src="https://www.akpkrab.pl/uploads/Certyfikat-jakosci-KDP.jpg" alt="Certyfikat jakości szkolenia przyznany Klubowi przez Komisję Działalności Podwodnej ZG PTTK." />
                    <img src="https://www.akpkrab.pl/uploads/Dyplom-1miejsce-2013.jpg" alt="Dyplom dla najlepszego centrum nurkowego w 2012 roku przyznany Klubowi przez Komisję Działalności Podwodnej." />
                    <img src="https://www.akpkrab.pl/uploads/Dyplom-1miejsce-2014.jpg" alt="Dyplom dla najlepszego centrum nurkowego w 2013 roku przyznany Klubowi przez Komisję Działalności Podwodnej." />
                </div>
                <br></br>

                <p className="text">
                    Oferując szeroką gamę kursów specjalistycznych, takich jak fotografia podwodna, nurkowanie w suchych skafandrach, nurkowanie podlodowe, jaskiniowe, wrakowo morskie, czy też nurkowanie z użyciem innych niż powietrze czynników oddechowych. Szkolenie w „Krabie” umożliwia rozwój w kierunku indywidualnych zainteresowań Kursanta. Ta różnorodność szkoleniowa Kraba sprawia, że od wielu lat Klub znajduję się w ścisłej czołówce centrów nurkowych w Polsce co potwierdzają dyplomy uznania przyznane przez Komisję Działalności Podwodnej ZG PTTK.
                </p>

                <p className="text">
                    Kursy odbywają się według standardów międzynarodowej organizacji CMAS i kończą się uzyskaniem międzynarodowych certyfikatów. Całoroczne szkolenia basenowe pomagają utrzymać dobrą kondycję i ogólną sprawność fizyczną, tak ważną w nurkowaniu.
                </p>

                <p className="text">
                    Od kilku lat w "Krabie" działa <a href="https://akpkrab.pl/szkolenia/sekcja-freedivingu">sekcja nurkowania na wstrzymanym oddechu</a>, której uczestnicy z powodzeniem walczą i odnoszą sukcesy na krajowych i zagranicznych zawodach, Mistrzostwach Polski oraz reprezentują nasz kraj na Mistrzostwach Świata. Z inicjatywy "Kraba" KDP uchwaliła wprowadzenie nowej specjalizacji - Freedivingu. W dowód uznania za inicjatywę i wyniki organizacja pierwszego kursu instruktorskiego została powierzona naszemu klubowi.
                </p>

                <p className="text">
                    AKP Krab AGH dzięki dużej liczbie zrzeszonych, zapewnia swoim członkom prawdziwie klubową atmosferę organizując liczne imprezy zarówno sportowe, jak i towarzyskie. Osobom wkraczającym w etap studiów umożliwia to lepszą integrację z uczelnią oraz środowiskiem akademickim.
                </p>
            </section>
        </div>
    );
}

export default WeWhoWeAre;