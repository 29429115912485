import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';
import { Link } from 'react-router-dom';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './SliderComponent.css';

const SliderComponent = () => {
    const [sliderItems, setSliderItems] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        // Fetch danych na starcie komponentu
        fetch("https://akpkrab.pl:443/sliderItems", {
            method: 'GET',
            headers: {
                'Authorization': 'a9b844871969b44bb5bb1845ca0e6b68669d544cab184422e76265e6f06b8068'
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setSliderItems(result); // Ustawienie sliderItems po fetchu
                    setIsLoaded(true);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            );
    }, []); // Pusty array oznacza, że useEffect uruchomi się tylko raz, na starcie komponentu

    const settings = {
        dots: true,
        infinite: true,
        speed: 750,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false,
    };

    const scrollToNews = () => {
        const newsSection = document.getElementById('news-section');
        if (newsSection) {
            newsSection.scrollIntoView({ behavior: 'smooth' });
        }
    };

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <div className="slider-container">
                <Slider {...settings}>
                    {sliderItems.slice().reverse().map((item, index) => {
                        const reversedIndex = sliderItems.length - 1 - index; // Odwrócony indeks
                        if (item.type === "active") {
                            if (item.link === "") {
                                return (
                                    <div key={index} className="slide">
                                        <img src={"https://www.akpkrab.pl/uploads/" + item.image} alt={item.title} />
                                        <div className="slide-content">
                                            <h2>{item.title}</h2>
                                            <p dangerouslySetInnerHTML={{ __html: item.short }}></p>
                                            <Link
                                                onClick={() => {
                                                    setTimeout(() => { setTimeout(() => { window.location.reload(); }, 0);; }, 0);
                                                }} to={`/slider/${reversedIndex}`}>Szczegóły</Link>
                                        </div>
                                    </div>
                                );
                            }
                            else {
                                return (
                                    <div key={index} className="slide">
                                        <img src={"https://www.akpkrab.pl/uploads/" + item.image} alt={item.title} />
                                        <div className="slide-content">
                                            <h2>{item.title}</h2>
                                            <p dangerouslySetInnerHTML={{ __html: item.short }}></p>
                                            <Link onClick={() => {
                                                setTimeout(() => { setTimeout(() => { window.location.reload(); }, 0);; }, 0);
                                            }} to={`${item.link}`}>Szczegóły</Link>
                                        </div>
                                    </div>
                                );
                            }
                        }
                    })}
                </Slider>
                <button className="scroll-to-news-btn" onClick={scrollToNews}>
                    Aktualności
                </button>
            </div>
        );
    }
};

export default SliderComponent;