import React from 'react';
import './Pages.css';

function WeStatute() {
    return (
        <div className="container">
            <div className="col-md-12">
                <header className="header-internal">
                    <h3>Statut Klubu</h3>
                </header>
                <section className="content">
                    <div className="txtBox">
                        {/* Rozdział I */}
                        <p style={{ textAlign: "center" }}>
                            <strong>
                                <span style={{ fontSize: "larger" }}>Rozdział I</span>
                            </strong>
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>Postanowienia ogólne</strong>
                        </p>
                        <p style={{ textAlign: "center" }}></p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§1</strong>
                        </p>
                        <p>
                            Stowarzyszenie nosi nazwę: Akademicki Klub Podwodny Krab AGH, w dalszych postanowieniach statutu zwane Klubem.
                            <br />
                            Stowarzyszenie może używać nazwy skróconej: AKP Krab AGH.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§2</strong>
                        </p>
                        <p>
                            Klub działa na terenie Rzeczpospolitej Polskiej i za granicą. Siedzibą Klubu jest Kraków.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§3</strong>
                        </p>
                        <p>
                            Klub jest organizacją zarejestrowaną na podstawie obowiązującego prawa o stowarzyszeniach i z tego tytułu posiada osobowość prawną.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§4</strong>
                        </p>
                        <p>
                            Klub może być członkiem krajowych i zagranicznych organizacji zrzeszających podmioty o tych samych lub podobnych celach działalności.
                            <br />
                            Klub w zakresie szkolenia nurkowego działa w oparciu o przepisy wydane przez Komisję Działalności Podwodnej Zarządu Głównego PTTK.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§5</strong>
                        </p>
                        <p>
                            Klub może używać wyróżniającego znaku graficznego oraz pieczęci zgodnie z obowiązującymi przepisami.
                        </p>

                        {/* Rozdział II */}
                        <br></br>
                        <p style={{ textAlign: "center" }}>
                            <strong>
                                <span style={{ fontSize: "larger" }}>Rozdział II</span>
                            </strong>
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>Cele i środki działania Klubu</strong>
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§6</strong>
                        </p>
                        <p>
                            1. Prowadzenie działalności szkoleniowej w zakresie nurkowania rekreacyjnego, technicznego i sportowego w środowisku akademickim Krakowa.
                            <br />
                            2. Organizowanie aktywnych form rekreacji dla członków Klubu.
                            <br />
                            3. Organizowanie wyjazdów turystyczno-nurkowych.
                            <br />
                            4. Upowszechnianie wiedzy o sportowych, naukowych oraz technicznych aspektach nurkowania.
                            <br />
                            5. Działalność w zakresie ochrony środowiska naturalnego, ze szczególnym uwzględnieniem środowiska wodnego.
                            <br />
                            6. Propagowanie wiedzy o ochronie środowiska naturalnego.
                            <br />
                            7. Współpraca w zakresie podejmowanych działań związanych z ochroną środowiska ze studentami i kadrą naukową Akademii Górniczo – Hutniczej w Krakowie.
                            <br />
                            8. Współpraca z organizacjami nurkowymi w Polsce i za granicą.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§7</strong>
                        </p>
                        <p>
                            Klub swe cele realizuje poprzez:
                            <br />
                            1. Organizowanie szkoleń nurkowych i innych związanych z działalnością wodniacką.
                            <br />
                            2. Organizowanie różnorodnych form turystyki podwodnej, w szczególności krajowych i zagranicznych wyjazdów oraz obozów szkoleniowych.
                            <br />
                            3. Urządzanie otwartych wykładów, prelekcji, odczytów, wystaw, pokazów filmów i zdjęć itp., związanych z działalnością Klubu.
                            <br />
                            4. Współpracę z innymi organizacjami nurkowymi w Polsce i za granicą.
                            <br />
                            5. Promocję nurkowania podczas targów sportów wodnych, festiwali o tematyce sportowej i innych.
                            <br />
                            6. Współpracę z Akademią Górniczo Hutniczą w Krakowie w zakresie promocji Klubu w środowisku szkolnym i akademickim.
                            <br />
                            7. Promocję Akademii Górniczo-Hutniczej w Krakowie w środowisku szkolnym i akademickim.
                            <br />
                            8. Wykorzystywanie osiągnięć naukowych pracowników i studentów Akademii Górniczo Hutniczej w Krakowie w ramach działań związanych z ochroną środowiska.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§8</strong>
                        </p>
                        <p>
                            1. Klub opiera swoją działalność na społecznej pracy członków.
                            <br />
                            2. Dla prowadzenia swoich spraw Klub może zatrudniać pracowników na zasadach określonych przez Zarząd.
                        </p>
                        {/* Rozdział III */}
                        <br></br>
                        <p style={{ textAlign: "center" }}>
                            <strong>
                                <span style={{ fontSize: "larger" }}>Rozdział III</span>
                            </strong>
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>Członkowie Klubu, ich prawa i obowiązki</strong>
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§9</strong>
                        </p>
                        <p>
                            Członków Klubu dzielimy na:
                            <br />
                            1. Członków zwyczajnych.
                            <br />
                            2. Członków wspierających.
                            <br />
                            3. Członków honorowych.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§10</strong>
                        </p>
                        <p>
                            Członkiem zwyczajnym Klubu może być:
                            <br />
                            1. Osoba fizyczna, posiadająca stopień nurkowy co najmniej KPD CMAS*.
                            <br />
                            2. Osoba fizyczna, która przedstawi pisemne pozytywne rekomendacje co najmniej dwóch członków Klubu.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§11</strong>
                        </p>
                        <p>
                            Członkiem wspierającym Klubu może zostać osoba fizyczna lub osoba prawna, o ile jest zainteresowana wspieraniem celów statutowych Klubu i w tym celu zadeklaruje pomoc finansową, rzeczową lub merytoryczną na rzecz ich realizacji. Osoba prawna działa w Klubie poprzez swojego przedstawiciela.
                        </p>

                        <p style={{ textAlign: "center" }}>
                            <strong>§12</strong>
                        </p>
                        <p>
                            Członkiem honorowym Klubu może być:
                            <br />
                            1. Osoba fizyczna – instruktor płetwonurkowania po 5 latach aktywnej działalności szkoleniowej na rzecz Klubu.
                            <br />
                            2. Inna osoba fizyczna, która wniosła wybitny wkład w działalność i rozwój Klubu.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§13</strong>
                        </p>
                        <p>
                            1. O przyjęciu w poczet członków w charakterze członka zwyczajnego lub wspierającego decyduje Zarząd Klubu na podstawie złożonej deklaracji członkowskiej.
                            <br />
                            2. O nadaniu tytułu członka honorowego decyduje w przypadku osoby, o której mowa w §12 ust. 1 Statutu – decyduje Zarząd Klubu, a w przypadku osoby fizycznej, o której mowa w §12 ust. 2 Statutu – decyduje Walne Zebranie Członków na wniosek Zarządu.
                            <br />
                            3. Zarząd prowadzi rejestr członków Klubu.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§14</strong>
                        </p>
                        <p>
                            Członkowie zwyczajni mają prawo:
                            <br />
                            1. Udziału i głosowania na Walnym Zebraniu Członków Klubu.
                            <br />
                            2. Czynnego i biernego prawa wyborczego w wyborach do wszystkich władz Klubu.
                            <br />
                            3. Wnioskowania we wszystkich sprawach Klubu.
                            <br />
                            4. Korzystania z urządzeń, majątku, rekomendacji i pomocy Klubu.
                            <br />
                            5. Udziału w działalności informacyjnej, szkoleniowej, promocyjnej, i innej podejmowanej w ramach realizacji celów statutowych Klubu na zasadach określonych przez Zarząd.
                            <br />
                            6. Kształtowania programu Klubu.
                        </p>
                        <p>
                            Członkom wspierającym i honorowym Klubu przysługują uprawnienia wskazane w ust. 1 pkt. c, d, e oraz f.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§15</strong>
                        </p>
                        <p>
                            Członkowie zwyczajni i wspierający Klubu mają obowiązek:
                            <br />
                            1. Przestrzegania Statutu, regulaminów i uchwał władz Klubu.
                            <br />
                            2. Aktywnego udziału w realizacji celów statutowych Klubu.
                            <br />
                            3. Regularnego opłacania składek członkowskich i innych świadczeń na rzecz Klubu.
                            <br />
                            4. Przestrzegania wytycznych KDP PTTK w zakresie bezpiecznego uprawiania płetwonurkowania, zgodnie ze swoimi uprawnieniami nurkowymi.
                        </p>
                        <p>
                            Członkowie honorowi Klubu mają obowiązek:
                            <br />
                            1. Przestrzegania statutu, regulaminów i uchwał władz Klubu.
                            <br />
                            2. Przestrzegania wytycznych KDP PTTK w zakresie bezpiecznego uprawiania płetwonurkowania, zgodnie ze swoimi uprawnieniami nurkowymi.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§16</strong>
                        </p>
                        <p>
                            Utrata członkostwa w Klubie następuje na skutek:
                            <br />
                            1. Dobrowolnej rezygnacji z członkostwa w Klubie, zgłoszonej na piśmie Zarządowi, po uprzednim uregulowaniu wszelkich zobowiązań odnośnie Klubu.
                            <br />
                            2. Śmierci członka lub utraty osobowości prawnej przez członka wspierającego będącego osobą prawną.
                        </p>
                        <p>
                            Zarząd może dokonać skreślenia z listy członków Klubu z powodu:
                            <br />
                            1. Nieprzestrzegania postanowień statutu, regulaminów i uchwał władz Klubu.
                            <br />
                            2. Działania na szkodę Klubu.
                            <br />
                            3. Dłuższej niż 2 lata przerwy w udziale w działalności Klubowej.
                            <br />
                            4. Zalegania z opłatą składek członkowskich lub innych zobowiązań wobec Klubu przez okres przekraczający jeden rok, po wcześniejszym upomnieniu przez Zarząd.
                            <br />
                            5. Na pisemny wniosek 10 członków Klubu.
                        </p>
                        <p>
                            Informację o skreśleniu z listy członków Klubu Zarząd przekazuje na piśmie osobie skreślonej.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§17</strong>
                        </p>
                        <p>
                            Osoba skreślona z listy członków Klubu ma prawo wniesienia odwołania do Rady Klubu w terminie 1 miesiąca od powzięcia wiadomości o podjęciu uchwały przez Zarząd Klubu, nie później jednak niż po upływie jednego roku od podjęcia uchwały. Uchwała Rady Klubu jest ostateczna.
                        </p>
                        <p>
                            Utrata tytułu członka honorowego następuje na podstawie dobrowolnej rezygnacji lub na podstawie uchwały Walnego Zebrania Członków podjętej na wniosek Zarządu.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§18</strong>
                        </p>
                        <p>
                            Członkostwo zwyczajne w Klubie ulega zawieszeniu na skutek:
                            <br />
                            1. Niezapłacenia składek członkowskich w terminie uprzednio wyznaczonym przez Zarząd Klubu.
                            <br />
                            2. Zarząd Klubu co najmniej raz do roku dokonuje weryfikacji członkostwa w Klubie.
                        </p>
                        {/* Rozdział IV */}
                        <br></br>
                        <p style={{ textAlign: "center" }}>
                            <strong>
                                <span style={{ fontSize: "larger" }}>Rozdział IV</span>
                            </strong>
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>Władze Klubu</strong>
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§19</strong>
                        </p>
                        <p>
                            Władzę w Klubie sprawuje:
                            <br />
                            1. Walne Zebranie Członków.
                            <br />
                            2. Zarząd.
                            <br />
                            3. Rada Klubu.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§20</strong>
                        </p>
                        <p>
                            1. Kadencja Zarządu i Rady Klubu trwa dwa lata.
                            <br />
                            2. W przypadku ustąpienia, wykluczenia lub śmierci członka Zarządu lub Rady Klubu w trakcie kadencji, jego skład osobowy jest uzupełniany poprzez dokooptowanie przez Zarząd lub Radę Klubu na pozostały okres kadencji nowego członka na nieobsadzone stanowisko. Nie dotyczy to stanowiska Prezesa Zarządu. Ogólna liczba członków dokooptowanych w tym trybie nie może przekraczać 1/3 składu Zarządu lub Rady Klubu.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§21</strong>
                        </p>
                        <p>
                            1. Walne Zebranie Członków jest najwyższą władzą Klubu.
                            <br />
                            2. W Walnym Zebraniu Członków biorą udział:
                            <br />
                            &nbsp;&nbsp;&nbsp;- z głosem stanowiącym – członkowie zwyczajni.
                            <br />
                            &nbsp;&nbsp;&nbsp;- z głosem doradczym – członkowie wspierający i honorowi.
                            <br />
                            3. Walne Zebranie Członków może być zwyczajne lub nadzwyczajne.
                            <br />
                            4. Sprawozdawcze Walne Zebranie Członków zwołuje się raz w roku, w terminie do 3 miesięcy po rozpoczęciu roku akademickiego.
                            <br />
                            5. Obradami Walnego Zebrania kieruje wybrany przez nie przewodniczący obrad.
                            <br />
                            6. Nadzwyczajne Walne Zebranie Członków zwołuje w szczególnie uzasadnionych przypadkach Zarząd:
                            <br />
                            &nbsp;&nbsp;&nbsp;- z własnej inicjatywy.
                            <br />
                            &nbsp;&nbsp;&nbsp;- na wniosek Rady Klubu.
                            <br />
                            &nbsp;&nbsp;&nbsp;- na wniosek 1/3 ogółu członków zwyczajnych Klubu.
                            <br />
                            7. Zarząd zwołuje Nadzwyczajne Walne Zebranie Członków w terminie do 30 dni od daty zgłoszenia wniosku.
                            <br />
                            8. O miejscu, terminie i porządku obrad Walnego Zebrania Członków Zarząd powiadamia członków pisemnie listem poleconym lub za pomocą poczty elektronicznej, co najmniej na 14 dni przed terminem zebrania.
                            <br />
                            9. Uchwały Walnego Zebrania Członków zapadają zwykłą większością głosów, jeżeli statut nie stanowi inaczej.
                            <br />
                            10. Walne Zebranie Członków obraduje wyłącznie nad sprawami, które zostały umieszczone w porządku obrad.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§22</strong>
                        </p>
                        <p>
                            1. Uchwały Walnego Zebrania Członków zapadają w głosowaniu jawnym, z wyjątkiem głosowań nad uchwałami o powołaniu lub odwołaniu członków organów Klubu, które zapadają w głosowaniu tajnym.
                            <br />
                            2. Walne Zebranie na wniosek członka zwyczajnego Klubu, może podjąć uchwałę o utajnieniu głosowania nad każdą podejmowaną uchwałą.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§23</strong>
                        </p>
                        <p>
                            Prawidłowo zwołane Walne Zebranie Członków Klubu jest władne podejmować prawomocne uchwały bez względu na ilość przybyłych członków.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§24</strong>
                        </p>
                        <p>
                            Do kompetencji Walnego Zebrania Członków w szczególności należy:
                            <br />
                            1. Udzielanie absolutorium ustępującemu Zarządowi.
                            <br />
                            2. Rozpatrywanie sprawozdań z działalności Zarządu i Rady Klubu.
                            <br />
                            3. Uchwalanie statutu i jego zmian.
                            <br />
                            4. Wybór i odwoływanie członków władz Klubu.
                            <br />
                            5. Ustalanie wytycznych polityki kadrowej, finansowej i gospodarczej Klubu.
                            <br />
                            6. Podejmowanie uchwały o rozwiązaniu Klubu i przeznaczeniu jego majątku.
                            <br />
                            7. Nadawanie i pozbawianie tytułu członka honorowego Klubu na wniosek Zarządu.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§25</strong>
                        </p>
                        <p>
                            1. Zarząd jest naczelną władzą Klubu. Kieruje on całokształtem działalności Klubu, zgodnie z uchwałami Walnego Zebrania Członków, reprezentuje go na zewnątrz i ponosi odpowiedzialność przed Walnym Zebraniem Członków.
                            <br />
                            2. Zarząd składa się z 3 do 5 członków.
                            <br />
                            3. Posiedzenia Zarządu odbywają się w zależności od potrzeb, nie rzadziej jednak niż raz w miesiącu.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§26</strong>
                        </p>
                        <p>
                            Do zakresu działania Zarządu należy:
                            <br />
                            1. Reprezentowanie Klubu na zewnątrz i działanie w jego imieniu.
                            <br />
                            2. Realizacja uchwał Walnego Zebrania Członków oraz postanowień statutu.
                            <br />
                            3. Sprawowanie zarządu nad majątkiem Klubu.
                            <br />
                            4. Podejmowanie decyzji w sprawie nabycia lub zbycia majątku nieruchomego i ruchomego.
                            <br />
                            5. Podejmowanie decyzji w sprawie zaciągania zobowiązań finansowych.
                            <br />
                            6. Uchwalanie wysokości składki wpisowej.
                            <br />
                            7. Uchwalanie wysokości składek członkowskich i ich podział.
                            <br />
                            8. Uchwalanie szczegółowych planów działalności Klubu.
                            <br />
                            9. Inicjowanie i kierowanie współpracą ze stowarzyszeniami i organizacjami o podobnym charakterze w kraju i za granicą.
                            <br />
                            10. Zwoływanie Walnego Zebrania Członków.
                            <br />
                            11. Podejmowanie uchwał w sprawach przyjmowania i skreślania członków Klubu.
                            <br />
                            12. Składanie sprawozdań ze swej działalności na Walnym Zebraniu Członków.
                            <br />
                            13. Przyjmowanie i skreślanie członków wspierających.
                            <br />
                            14. Występowanie z wnioskami do Walnego Zebrania Członków o nadanie tytułu członka honorowego.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§27</strong>
                        </p>
                        <p>
                            1. Decyzje Zarządu podejmowane są w formie uchwał, zwykłą większością głosów.
                            <br />
                            2. W przypadku równej liczby głosów rozstrzyga głos Prezesa Zarządu.
                            <br />
                            3. Do ważności podejmowanych uchwał konieczna jest obecność co najmniej połowy członków Zarządu.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§28</strong>
                        </p>
                        <p>
                            1. Prezesowi Zarządu przysługuje prawo weta wstrzymującego realizację każdej uchwały Zarządu, podjęciu której był przeciwny.
                            <br />
                            2. W przypadku skorzystania przez Prezesa z prawa weta, Zarząd obowiązany jest zwołać Nadzwyczajne Walne Zebranie Członków, w celu rozstrzygnięcia, czy zawetowana uchwała będzie realizowana.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§29</strong>
                        </p>
                        <p>
                            1. Rada Klubu jest organem Klubu powołanym do sprawowania kontroli nad jego działalnością.
                            <br />
                            2. Rada Klubu składa się z 3 do 5 członków.
                            <br />
                            3. Rada Klubu wybiera ze swego składu przewodniczącego, zastępcę i sekretarza.
                            <br />
                            4. Rada Klubu jest odpowiedzialna za swoją działalność przed Walnym Zebraniem Członków.
                            <br />
                            5. Członkowie Rady Klubu nie mogą pełnić żadnych innych funkcji we władzach Klubu.
                            <br />
                            6. Uchwały Rady Klubu podejmowane są w głosowaniu jawnym, zwykłą większością głosów, w obecności co najmniej 2/3 składu Rady Klubu. W przypadku równej liczby głosów rozstrzyga głos Przewodniczącego Rady Klubu. Na podstawie uchwały pełnego składu, Rada Klubu może podejmować uchwały w głosowaniu tajnym.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§30</strong>
                        </p>
                        <p>
                            Do zakresu działania Rady Klubu należy:
                            <br />
                            1. Kontrolowanie całokształtu działalności Klubu.
                            <br />
                            2. Występowanie do Zarządu z wnioskami wynikającymi z przeprowadzonych kontroli.
                            <br />
                            3. Prawo żądania zwołania Nadzwyczajnego Walnego Zebrania Członków w razie stwierdzenia niewywiązywania się przez Zarząd z jego statutowych obowiązków, a także prawo żądania zwołania posiedzenia Zarządu.
                            <br />
                            4. Składanie na Walnym Zebraniu Członków wniosków o udzielenie absolutorium Zarządowi Klubu.
                            <br />
                            5. Rozpatrywanie odwołań od decyzji Zarządu.
                            <br />
                            6. Składanie sprawozdań ze swej działalności na Walnym Zebraniu Członków.
                        </p>
                        <p>
                            Członkowie Rady Klubu mają prawo żądania od członków i władz Klubu wszystkich szczebli złożenia pisemnych lub ustnych wyjaśnień dotyczących kontrolowanych spraw.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§31</strong>
                        </p>
                        <p>
                            Przewodniczący lub upoważniony członek Rady Klubu ma prawo brać udział w posiedzeniach Zarządu oraz innych jednostek organizacyjnych Klubu, z głosem doradczym.
                        </p>

                        {/* Rozdział V */}
                        <br></br>
                        <p style={{ textAlign: "center" }}>
                            <strong>
                                <span style={{ fontSize: "larger" }}>Rozdział V</span>
                            </strong>
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>Struktury szkoleniowe Klubu</strong>
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§32</strong>
                        </p>
                        <p>
                            Szkolenie w Klubie prowadzi Zespół Instruktorski, w skład którego wchodzą instruktorzy – członkowie Klubu, prowadzący działalność szkoleniową w Klubie oraz z urzędu Prezes Klubu.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§33</strong>
                        </p>
                        <p>
                            W skład Zespołu Instruktorskiego wchodzą instruktorzy płetwonurkowania, którzy przynajmniej raz na 2 lata:
                            <br />
                            1. Prowadzili szkolenie basenowe w Klubie.
                            <br />
                            2. Prowadzili szkolenie na dowolny stopień KDP/CMAS organizowane przez Klub lub uczestniczyli w akcji szkoleniowej organizowanej przez Klub.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§34</strong>
                        </p>
                        <p>
                            Członkowie Zespołu Instruktorskiego mają prawo organizowania i wizytowania akcji nurkowych.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§35</strong>
                        </p>
                        <p>
                            1. Zespół Instruktorski wybiera ze swego grona Szefa Szkolenia. Szef Szkolenia może brać udział w posiedzeniach Zarządu z głosem doradczym.
                            <br />
                            2. Zespół Instruktorski podejmuje decyzje w formie uchwał, zwykłą większością głosów, bez względu na liczbę obecnych członków.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§36</strong>
                        </p>
                        <p>
                            1. Posiedzenia Zespołu Instruktorskiego zwołuje i nim przewodniczy Szef Szkolenia. Prawo zwołania posiedzenia Zespołu Instruktorskiego ma również Prezes Klubu.
                            <br />
                            2. W posiedzeniach Zespołu Instruktorskiego mogą brać udział bez prawa głosu osoby będące członkami zwyczajnymi Klubu, posiadające stopień wyszkolenia KDP CMAS*** (lub równoważny), po wyrażeniu zgody przez Zespół Instruktorski.
                        </p>

                        {/* Rozdział VI */}
                        <br></br>
                        <p style={{ textAlign: "center" }}>
                            <strong>
                                <span style={{ fontSize: "larger" }}>Rozdział VI</span>
                            </strong>
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>Majątek i Fundusze Klubu</strong>
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§37</strong>
                        </p>
                        <p>
                            Majątek Klubu stanowią:
                            <br />
                            1. Nieruchomości, ruchomości i fundusze.
                            <br />
                            2. Wpisowe i składki członkowskie.
                            <br />
                            3. Darowizny, zapisy i spadki.
                            <br />
                            4. Wpływy z działalności statutowej Stowarzyszenia.
                            <br />
                            5. Dochody z własnej działalności.
                            <br />
                            6. Dochody z majątku Klubu.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§38</strong>
                        </p>
                        <p>
                            W Klubie obowiązują następujące składki:
                            <br />
                            1. Jednorazowa składka wpisowa, zwana wpisowym.
                            <br />
                            2. Obowiązkowa coroczna składka członkowska, upoważniająca do korzystania ze sprzętu klubowego na preferencyjnych warunkach.
                            <br />
                            3. Składka szkoleniowa, upoważniająca do uczestnictwa w zajęciach szkoleniowych.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§39</strong>
                        </p>
                        <p>
                            Wysokość poszczególnych składek na każdy kolejny rok ustala Zarząd po zasięgnięciu opinii Rady Klubu i podaje do wiadomości członków Klubu najpóźniej podczas Walnego Zebrania.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§40</strong>
                        </p>
                        <p>
                            1. Członkowie honorowi są zwolnieni z płacenia składek członkowskich.
                            <br />
                            2. Członkowie wspierający mogą być zwolnieni z płacenia składek członkowskich na podstawie uchwały Zarządu.
                            <br />
                            3. Instruktorzy prowadzący działalność szkoleniową w Klubie zwolnieni są z obowiązku płacenia składek członkowskich.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§41</strong>
                        </p>
                        <p>
                            Klub może prowadzić działalność gospodarczą, na zasadach określonych w odrębnych przepisach.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§42</strong>
                        </p>
                        <p>
                            Do składania oświadczeń woli w sprawach majątkowych oraz niemajątkowych Klubu uprawnieni są Prezes Zarządu jednoosobowo lub dwaj członkowie Zarządu działający łącznie.
                        </p>

                        {/* Rozdział VII */}
                        <br></br>
                        <p style={{ textAlign: "center" }}>
                            <strong>
                                <span style={{ fontSize: "larger" }}>Rozdział VII</span>
                            </strong>
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>Zmiany Statutu i rozwiązanie Klubu</strong>
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§43</strong>
                        </p>
                        <p>
                            1. Uchwalenie statutu lub jego zmiana oraz podjęcie uchwały o rozwiązaniu Klubu przez Walne Zebranie Członków wymaga kwalifikowanej większości 2/3 głosów, przy obecności co najmniej połowy członków uprawnionych do głosowania.
                            <br />
                            2. Podejmując uchwałę o rozwiązaniu Klubu, Walne Zebranie Członków określa sposób jego likwidacji oraz przeznaczenie majątku Klubu.
                        </p>
                        <p style={{ textAlign: "center" }}>
                            <strong>§44</strong>
                        </p>
                        <p>
                            W sprawach nieuregulowanych w niniejszym statucie zastosowanie mają przepisy ustawy Prawo o Stowarzyszeniach.
                        </p>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default WeStatute;