import React from 'react';
import './Pages.css';

function WeManagementAndCouncil() {
    return (
        <div className="container">
            <div className="col-md-12">
                <header className="header-internal">
                    <h3>Zarząd i Rada Klubu</h3>
                </header>
                <section className="content">
                    {/* Zarząd Klubu */}
                    <div className="txtBox">
                        <p>
                            <strong>
                                <u>Aktualny skład Zarządu Akademickiego Klubu Podwodnego Krab AGH:</u>
                            </strong>
                        </p>
                        <p>
                            <strong>Krzysztof Sekuła</strong>
                            <em> – Prezes</em>
                        </p>
                        <p>
                            <strong>Krzysztof Sojda</strong>
                            <em> – Wiceprezes ds. organizacyjnych</em>
                        </p>
                        <p>
                            <strong>Wojciech Sikora</strong>
                            <em> – Wiceprezes ds. finansowych</em>
                        </p>
                        <p>
                            <strong>Tomasz Urban</strong>
                            <em> – Wiceprezes ds. technicznych</em>
                        </p>
                        <p>
                            <strong>Marta Łukaszewicz</strong>
                            <em> – Wiceprezes ds. administracyjnych</em>
                        </p>
                        <br></br>
                        <p>
                            <strong>Mateusz Pszczółka</strong>
                            <em> – Szef szkolenia</em>
                        </p>
                        <br></br>
                    </div>

                    {/* Rada Klubu */}
                    <div className="txtBox">
                        <p>
                            Wszelkie uwagi odnośnie funkcjonowania Klubu, problemy, skargi i wnioski prosimy zgłaszać Radzie Klubu, która w tym celu została powołana.
                        </p>
                        <p>
                            <strong>Zapewniamy, że wszystkim postaramy się pomóc; gwarantujemy pełną anonimowość.</strong>
                        </p>
                        <p>
                            <u>
                                <strong>Nasz email:</strong>
                            </u>{" "}
                            <em>rada-klubu-akp-krab@googlegroups.com</em>
                        </p>
                        <br></br>
                        <p>
                            <u>
                                <strong>Aktualny skład Rady Klubu:</strong>
                            </u>
                        </p>
                        <p><strong>Bartłomiej Wiendlocha</strong><em> - przewodniczący Rady Klubu</em></p>
                        <p><strong>Justyna Machał-Piłat</strong></p>
                        <p><strong>Damian Walczyk</strong></p>
                        <p><strong>Jakub Sęk</strong></p>
                        <p><strong>Mateusz Pszczółka</strong></p>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default WeManagementAndCouncil;
