import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import './SubPage.css';

const NewsDetail = () => {
    const [newsItems, setNewsItems] = useState([]);

    useEffect(() => {
        // Pobieranie danych na starcie komponentu
        fetch("https://akpkrab.pl:443/newsItems", {
            method: 'GET',
            headers: {
                'Authorization': 'a9b844871969b44bb5bb1845ca0e6b68669d544cab184422e76265e6f06b8068'
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setNewsItems(result); // Ustawienie newsItems po fetchu
                }
            );
    }, []);

    const { id } = useParams(); // Pobranie ID z URL
    const news = newsItems[id]; // Pobranie odpowiedniej aktualności na podstawie ID

    if (!news) {
        return (
            <div className="subpage-container">
                <h1 className="subpage-title">Aktualności nie znaleziono</h1>
                <div className="subpage-content">
                    <img src="https://via.placeholder.com/1920x1080" alt="Brak" />
                    <p>Aktualności nie znaleziono</p>
                    <a href="https://www.akpkrab.pl" className="button-back">Powrót do strony głównej</a>
                </div>
            </div>
        );
    }
    if (news.type === "active") {
        return (
            <div className="subpage-container">
                {/* Sekcja zdjęcia */}
                <div className="image-header">
                    <img
                        src={`https://www.akpkrab.pl/uploads/${news.image}`}
                        alt={news.title}
                        className="image-full"
                    />
                    <div className="title-overlay">
                        <h1 className="title-text">{news.title}</h1>
                    </div>
                </div>

                {/* Sekcja content */}
                <section className="content">
                    <div className="txtBox">
                        <p className="subpage-text" dangerouslySetInnerHTML={{ __html: news.text }}></p>
                    </div>
                    <a href="https://www.akpkrab.pl" className="button-back">Powrót do strony głównej</a>
                </section>
            </div>
        );
    }
};

export default NewsDetail;