import React from 'react';
import './Pages.css';

function WeClubDoctors() {
    return (
        <div className="container">
            <div className="col-md-12">
                <header className="header-internal">
                    <h3>Lekarze klubowi</h3>
                </header>
                <section className="content">
                    <div className="txtBox">
                        <p>Lekarze współpracujący z Klubem uprawnieni do przeprowadzania badań kwalifikacyjnych do uprawiania płetwonurkowania:</p>
                        <p>
                            <strong>Katarzyna Turzańska</strong> – tel. kom.: (+48) 788 973 064,
                            <br />
                            <strong>Marcin Panek</strong> – tel. kom.: (+48) 601 197 586,
                            <br />
                            <strong>Roman Sławek</strong> – tel. kom.: (+48) 502 226 005,
                        </p>
                        <p>
                            <u>
                                <strong>Wymagany komplet badań:</strong>
                            </u>
                            <br />
                            • morfologia z rozmazem,
                            <br />
                            • badanie poziomu glukozy wykonane na czczo,
                            <br />
                            • badanie moczu (badanie a nie tylko sam opis od lekarza),
                            <br />
                            • RTG klatki piersiowej (badanie a nie tylko sam opis od lekarza),
                            <br />
                            • EKG
                        </p>
                        <p>
                            <u>
                                <strong>Badania dodatkowe:</strong>
                            </u>
                            <br />
                            <strong>Badanie laryngologiczne</strong> - wymagane, jeśli macie (lub mieliście) problem z: uszami, zatokami, nosem, gardłem, słuchem.
                            <br />
                            W pozostałych przypadkach dobrze jest takie badanie wykonać przed pierwszym nurkowaniem; dzięki temu unikniecie niepotrzebnych stresów podczas Waszego pierwszego zanurzenia ze sprzętem nurkowym.
                            <br />
                            Jeśli ktoś do tej pory nie leczył się laryngologicznie i nie ma żadnych dolegliwości (z wymienionych powyżej) to nasz lekarz nurkowy wykona podstawowe badania laryngologiczne na miejscu.
                        </p>
                        <p>
                            Jeśli <strong>leczycie się na jakieś dolegliwości u specjalistów</strong>, to przynieście również wyniki badań/diagnozy związane z Waszym leczeniem, tak aby nasz lekarz mógł na ich podstawie rzetelnie ocenić Wasz stan zdrowia.
                        </p>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default WeClubDoctors;
