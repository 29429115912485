import React from 'react';
import './Pages.css';

function WeCalendar24() {
    return (
        <div className="container">
            <div className="col-md-12">
                <header className="header-internal">
                    <h3>Kalendarium krabowe '24</h3>
                </header>
                <section className="content">
                    <div className="txtBox">
                        <h5 style={{ textAlign: "center" }}>
                            <span style={{ fontSize: "larger" }}>Ahoj Kraby!</span>
                        </h5>
                        <h5 style={{ textAlign: "center" }}>
                            <span style={{ fontSize: "larger" }}>
                                Poniżej znajdziecie kalendarium Krabowe na nadchodzący sezon.
                            </span>
                        </h5>
                        <p>&nbsp;</p>
                        <table
                            style={{
                                margin: "0 auto", // Centruje tabelę na stronie
                                textAlign: "center", // Wyrównuje zawartość tekstową
                            }}
                        >
                            <tbody>
                                <tr>
                                    <td>
                                        <img
                                            src="https://www.akpkrab.pl/uploads/kalendarium.png"
                                            alt="Kalendarium krabowe"
                                            style={{
                                                maxWidth: "100%", // Obrazek dostosowuje się do szerokości okna
                                                height: "auto", // Proporcjonalna wysokość
                                                display: "block", // Blokowy układ do centrowania
                                                margin: "0 auto", // Obrazek na środku
                                            }}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <p>&nbsp;</p>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default WeCalendar24;