import React from 'react';
import { useParams } from 'react-router-dom';
import coursesData from './CoursesData.js';
import './Pages.css';

function CoursePage() {
    const { courseId } = useParams(); // Pobranie ID kursu z URL
    const course = coursesData.find((c) => c.id === courseId);

    if (!course) {
        return (
            <div className="container">
                <div className="col-md-12">
                    <header className="header-internal">
                        <h3>Kurs nie znaleziony</h3>
                    </header>
                    <p>Nie znaleziono kursu o podanym identyfikatorze.</p>
                </div>
            </div>
        );
    }

    return (
        <div className="container">
            <div className="col-md-12">
                <header className="header-internal">
                    <h3>{course.title}</h3>
                </header>
                <section className="content">
                    <p className="subpage-text" dangerouslySetInnerHTML={{ __html: course.description }}></p>
                    <img
                        src={course.image}
                        alt={course.title}
                        className="course-image"
                    />
                    <p className="subpage-text" dangerouslySetInnerHTML={{ __html: course.details }}></p>
                    <a href="https://akpkrab.pl/szkolenia/opis-kursow-nurkowych" className="button-back">Powrót do listy kursów</a>
                </section>
            </div>
        </div>
    );
}

export default CoursePage;
