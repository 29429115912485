import React from 'react';
import './Pages.css';

function TrainingPoolSchedule() {
    return (
        <div className="container">
            <div className="col-md-12">
                <header className="header-internal">
                    <h3>Harmonogram treningów basenowych</h3>
                </header>
                <section className="content">
                    <div className="txtBox">
                        <p>
                            Zajęcia basenowe w sezonie <strong>2024/2025</strong> odbywają się wg następującego harmonogramu:
                        </p>
                        <br></br>
                        <h4 style={{ textDecoration: 'underline', fontWeight: 'bold' }}>Grupa stopniowa:</h4>
                        <br></br>
                        <p>
                            Wzorem lat ubiegłych i w tym roku uruchamiamy treningi basenowe dla osób posiadających już stopień
                            nurkowy. W tym sezonie zmieniamy nieco formułę treningów. Z racji niewielkiego obłożenia niektórych
                            grup, w sezonie <strong>2024/2025</strong> uruchamiamy tylko jedną grupę, bez podziału na stopień
                            zaawansowania.
                        </p>
                        <br></br>
                        <p>
                            <strong>Grupa 1:</strong> Środy, godzina 20:30, basen YMCA
                        </p>
                        <br></br>
                        <p>
                            Zajęcia rozpoczynają się <strong>13.11.2024</strong> i kończą <strong>14.05.2025</strong> (co daje
                            łącznie 25 treningów). Treningi odbywają się w każdą środę oprócz <strong>25.12.2024</strong> i{' '}
                            <strong>01.01.2025</strong>.
                        </p>
                        <br></br>
                        <p>Minimalna liczba uczestników: 8, maksymalna: 12</p>
                        <br></br>
                        <p>
                            Cena zajęć stopniowych: <strong>950 zł</strong> (płatne w 2 ratach: <strong>500 zł</strong> przy
                            zapisach, <strong>450 zł</strong> w lutym).{' '}
                            <a
                                href="https://docs.google.com/forms/d/1sfy6ny1IIHya4VF6kp1aWuyyvKlFEjcTmaps9H_1eIU/edit"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Formularz zapisów
                            </a>
                        </p>
                        <br></br><br></br>
                        <h4 style={{ textDecoration: 'underline', fontWeight: 'bold' }}>Grupy początkujące:</h4>
                        <br></br>
                        <p>
                            Treningi obejmują cykl <strong>6 spotkań</strong>, podczas których nauczycie się podstaw pływania z ABC,
                            takich jak np. przedmuchiwanie maski. Zajęcia prowadzą nasi doświadczeni instruktorzy. Od drugich zajęć
                            będzie wymagany własny zestaw ABC (płetwy, maska, rurka).
                        </p>
                        <br></br>
                        <p>
                            Po 6 spotkaniach na basenie, gdzie przećwiczycie sobie podstawowe ćwiczenia przydatne na kurs P1,
                            zaprosimy Was na intro basenowe. Intro to innymi słowy nurkowanie zapoznawcze, gdzie będziecie mogli
                            zejść pod wodę w pełnym sprzęcie pod czujnym okiem instruktora. Odbywać się będzie w soboty, również na
                            basenie YMCA. Szczegółowy termin oraz godzinę podamy w późniejszym czasie osobom zapisanym na daną grupę
                            basenową. Wyjątkiem jest IV grupa basenowa, która, z racji terminu, będzie miała intro w połowie
                            treningów.
                        </p>
                        <br></br>
                        <p>
                            W maju zaprosimy wszystkie grupy basenowe na intro nurkowe na wodach otwartych.
                        </p>
                        <br></br>
                        <p>
                            Cena zajęć (w tym 6 treningów basenowych, intro basenowe oraz intro na wodach otwartych) wynosi{' '}
                            <strong>450 zł</strong>, płatne w czasie zapisów.{' '}
                            <a
                                href="https://docs.google.com/forms/d/e/1FAIpQLSdtRrD2SXMHcPIFwiZSSqLEHUaO4FUGEUKG59RPYdZPTc5D0g/viewform?usp=sf_link"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Formularz zapisów
                            </a>
                        </p>
                        <br></br>
                        <h5>
                            <strong>Grupa 1:</strong> Środy, godzina 20:30, basen YMCA
                        </h5>
                        <br></br>
                        <p>
                            Terminy grupy basenowej I: <strong>13.11.2024 - 18.12.2024</strong>
                        </p>
                        <br></br>
                        <h5>
                            <strong>Grupa 2:</strong> Środy, godzina 20:30, basen YMCA
                        </h5>
                        <br></br>
                        <p>
                            Terminy grupy basenowej II: <strong>08.01.2024 - 12.02.2025</strong>
                        </p>
                        <br></br>
                        <h5>
                            <strong>Grupa 3:</strong> Środy, godzina 20:30, basen YMCA
                        </h5>
                        <br></br>
                        <p>
                            Terminy grupy basenowej III: <strong>19.02.2025 - 26.03.2025</strong>
                        </p>
                        <br></br>
                        <h5>
                            <strong>Grupa 4:</strong> Środy, godzina 20:30, basen YMCA
                        </h5>
                        <br></br>
                        <p>
                            Terminy grupy basenowej IV: <strong>02.04.2025 - 07.05.2025</strong>
                        </p>
                        <br></br>
                        <p>
                            <strong>Treningi odbywają się na basenie</strong>{' '}
                            <a
                                href="https://goo.gl/maps/ADCGikUFuE6q41Sn9"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                YMCA przy ul. Krowoderskiej 8
                            </a>
                        </p>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default TrainingPoolSchedule;
