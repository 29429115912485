import React, { useState, useEffect } from 'react';
import './Pages.css';
import { useNavigate } from 'react-router-dom';

function TrainingCoursesTable() {
    const navigate = useNavigate();
    const [isMobile, setIsMobile] = useState(false);

    // Funkcja obsługująca kliknięcie w kurs
    const handleCellClick = (course) => {
        if (course.link) {
            navigate("/course" + course.link);
        }
    };

    // Funkcja do sprawdzania szerokości okna
    const updateIsMobile = () => {
        setIsMobile(window.innerWidth <= 768); // Dla ekranów <= 768px przełączamy na mobilny układ
    };

    useEffect(() => {
        updateIsMobile();
        window.addEventListener('resize', updateIsMobile); // Aktualizujemy widok przy zmianie rozmiaru okna
        return () => window.removeEventListener('resize', updateIsMobile);
    }, []);

    const courses = [
        { name: 'Płetwonurek KDP/CMAS * (P1)', coords: [6, 1], link: '/p1', style: 'merged', colspan: 2 },
        { name: 'Płetwonurek Nocny (PNO)', coords: [6, 2], link: '/pno', style: 'normal' },
        { name: 'Płetwonurek Nawigator (PNA)', coords: [7, 2], link: '/pna', style: 'normal' },
        { name: 'Płetwonurek w Skafandrze Suchym (PSS)', coords: [7, 1], link: '/pss', style: 'normal' },
        { name: 'Płetwonurek Fotograf (PF1)', coords: [8, 1], link: '/pf1', style: 'normal' },
        { name: 'Płetwonurek Nitroksowy (PN1)', coords: [3, 1], link: '/pn1', style: 'normal' },
        { name: 'Płetwonurek w Zestawie Butlowym (PZB)', coords: [4, 1], link: '/pzb', style: 'normal' },
        { name: 'Płetwonurek w Konfiguracji Bocznej (PKB1)', coords: [5, 1], link: '/pkb1', style: 'normal' },
        { name: 'Płetwonurek Podlodowy (PPL)', coords: [8, 2], link: '/ppl', style: 'normal' },
        { name: 'Płetwonurek Wrakowo-Morski (PWM)', coords: [9, 2], link: '/pwm', style: 'normal' },
        { name: 'Płetwonurek Archeolog (PA)', coords: [10, 2], link: '/pa', style: 'normal' },
        { name: 'Płetwonurek Ekolog (PEK)', coords: [11, 2], link: '/pek', style: 'normal' },
        { name: 'Płetwonurek Eksplorator (PE)', coords: [6, 3], link: '/pe', style: 'merged', colspan: 2 },
        { name: 'Płetwonurek Poszukiwacz-Wydobywca (PPW)', coords: [8, 3], link: '/ppw', style: 'normal' },
        { name: 'Płetwonurek Działań Proobronnych i Kryzysowych (PPK)', coords: [7, 3], link: '/ppk', style: 'normal' },
        { name: 'Płetwonurek KDP/CMAS ** (P2)', coords: [6, 4], link: '/p2', style: 'merged', colspan: 2 },
        { name: 'Zaawansowany Płetwonurek Nitroksowy (PN2)', coords: [4, 4], link: '/pn2', style: 'normal' },
        { name: 'Płetwonurek Jaskiniowy (PJ1)', coords: [5, 4], link: '/pj1', style: 'normal' },
        { name: 'Płetwonurek KDP/CMAS *** (P3)', coords: [6, 5], link: '/p3', style: 'merged', colspan: 2 },
        { name: 'Płetwonurek Prowadzący Intro (PPI)', coords: [7, 5], link: '/ppi', style: 'normal' },
        { name: 'Płetwonurek KDP/CMAS **** (P4)', coords: [6, 6], link: '/p4', style: 'merged', colspan: 2 },
        { name: 'Freediver Basic KDP/CMAS (PFD)', coords: [1, 1], link: '/freediver-basic', style: 'merged' },
        { name: 'Freediver I KDP/CMAS (PFD1)', coords: [1, 3], link: '/freediver-i', style: 'merged' },
        { name: 'Freediver II KDP/CMAS (PFD2)', coords: [1, 4], link: '/freediver-ii', style: 'merged' },
        { name: 'Freediver III KDP/CMAS (PFD3)', coords: [1, 5], link: '/freediver-iii', style: 'merged' },
    ];

    const rows = 6;
    const cols = 11;

    const table = Array.from({ length: rows }, (_, y) =>
        Array.from({ length: cols }, (_, x) => {
            const course = courses.find(
                (c) => c.coords[1] === y + 1 && c.coords[0] === x + 1
            );

            if (course) {
                return (
                    <td
                        key={`${x}-${y}`}
                        className={course.style === 'merged' ? 'merged' : 'normal'}
                        colSpan={course.colspan || 1}
                        onClick={() => handleCellClick(course)}
                    >
                        {course.name}
                    </td>
                );
            }

            return <td key={`${x}-${y}`} />;
        })
    );

    return (
        <div className="container">
            <div className="col-md-12">
                <header className="header-internal">
                    <h3>Opis kursów nurkowych</h3>
                </header>
                <section className="content">
                    <p>
                        <em>Kliknij nazwę kursu, aby zobaczyć jego szczegółowy opis.</em>
                    </p>
                    <br />
                    {!isMobile ? (
                        <table className="courses-table">
                            <tbody>
                                {table.map((row, index) => (
                                    <tr key={index}>{row}</tr>
                                ))}
                            </tbody>
                        </table>
                    ) : (
                        <div className="course-list">
                            {courses.map((course, index) => (
                                <div
                                    key={index}
                                    className={`course-item ${course.style}`}
                                    onClick={() => handleCellClick(course)}
                                >
                                    {course.name}
                                </div>
                            ))}
                        </div>
                    )}
                </section>
            </div>
        </div>
    );
}

export default TrainingCoursesTable;
