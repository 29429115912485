import React from "react";
import "./Pages.css";

const TrainingFreedivingSection = () => {
    return (
        <div className="container">
            <div className="col-md-12">
                <header className="header-internal">
                    <h3>Sekcja freedivingowa</h3>
                </header>
                <section className="content">
                    <div className="txtBox">
                        <p>
                            Krabowa sekcja freedivingowa to najlepsza okazja do trenowania freedivingu w Krakowie pod okiem utytułowanej kadry przez niemal cały rok akademicki.
                        </p>
                        <p>
                            Sekcja freedivingowa to cotygodniowe treningi od połowy listopada do połowy maja. W tym czasie ilość i zakres treningów znacznie przekracza minimum programowe kursu
                            <strong> CMAS Freediver Basic</strong>, który rozpisany jest na 2 dni szkoleniowe.
                        </p>
                        <br></br>
                        <div className="images">
                            <a href="https://www.meszka.com">
                                <img
                                    src="https://krab.agh.edu.pl/uploads/szkolenia/freediving.jpg"
                                    alt="Pływanie dynamiczne w płetwach - jedna z konkurencji rozgrywanych podczas basenowych mistrzostw polski we freedivingu."
                                    title="Pływanie dynamiczne w płetwach - jedna z konkurencji rozgrywanych podczas basenowych mistrzostw polski we freedivingu."
                                    style={{
                                        width: '100%',
                                        maxWidth: '600px',
                                        height: 'auto',
                                        borderRadius: '8px',
                                        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                        marginTop: '20px',
                                    }}
                                />
                            </a>
                        </div>
                        <p className="image-caption" style={{ textAlign: "center" }}>
                            fot. Rafał Meszka, <a href="https://www.meszka.com" target="_blank" rel="noopener noreferrer">www.meszka.com</a>
                        </p>
                        <br></br>
                        <p>
                            Krabowi freediverzy odnoszą sukcesy na zawodach ogólnopolskich jak i międzynarodowych. Uzyskać wysoki wynik podczas zawodniczego debiutu w pierwszym sezonie treningów? W Krabie nie ma rzeczy niemożliwych.
                        </p>
                        <br></br>
                        <p>
                            Jesteśmy dumni z rosnącej w siłę – i to w tak spektakularny sposób - jedynej w swoim rodzaju sekcji freedivingowej w Krakowie. U dołu strony tabela z wynikami osiąganymi przez Krabowych freediverów.
                        </p>
                    </div>
                </section>
            </div>
        </div>
    );
};

export default TrainingFreedivingSection;
