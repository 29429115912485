import React from 'react';
import './Pages.css';

function TrainingPool() {
    return (
        <div className="container">
            <div className="col-md-12">
                <header className="header-internal">
                    <h3>Treningi basenowe</h3>
                </header>
                <section className="content">
                    <div className="txtBox">
                        <p>
                            Krab już od 60-ciu lat specjalizuje się w prowadzeniu basenowych zajęć kondycyjnych dla płetwonurków. Kilka
                            pokoleń instruktorów wypracowało Krabowską Szkołę Nurkową, której fundamentem jest wysoka sprawność
                            pływacko-bezdechowa członków naszego Klubu. Rok rocznie, nasi klubowicze wierni klubowym tradycjom szlifują
                            hart ducha i ciała na zajęciach basenowych. Każdy z nas wie, że dobry zatrzymany oddech zwiększa margines
                            bezpieczeństwa podczas akcji nurkowych.
                        </p>
                        <div className="images" style={{ textAlign: 'center' }}>
                            <img
                                src="https://www.akpkrab.pl/uploads/zajecia_basenowe_1.jpg"
                                alt="Zajęcia pływackie w sprzęcie ABC (maska, płetwy, fajka)."
                                title="Zajęcia pływackie w sprzęcie ABC (maska, płetwy, fajka)."
                                style={{
                                    width: '100%',
                                    maxWidth: '600px',
                                    height: 'auto',
                                    borderRadius: '8px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                    marginTop: '20px',
                                }}
                            />
                        </div>
                        <p>
                            Odrobina aktywności fizycznej jest niezbędna do utrzymania minimum wydolności krążeniowo-oddechowej, tak
                            ważnej dla naszego zdrowia, bezpiecznego uprawiania turystyki kwalifikowanej czy każdego sportu na
                            poziomie amatorskim. Samemu ciężko się zmobilizować do systematycznego trenowania. Bolący kręgosłup od
                            siedzenia przed komputerem i wieczne obietnice, że w końcu się za siebie wezmę…
                        </p>
                        <br></br>
                        <p>
                            Mamy na to lekarstwo! Zapisz się do nas na zajęcia basenowe! Nic tak nie mobilizuje do systematycznego
                            treningu jak opłacona składka basenowa, grupa znajomych i czujne oko instruktora - motywujące i pilnujące,
                            byśmy się nie obijali{" "}
                        </p>
                        <br></br>
                        <h4 style={{ textDecoration: 'underline', fontWeight: 'bold' }}>
                            Jak wygląda uczestnictwo w krabowej sekcji basenowej?
                        </h4>
                        <br></br>
                        <p>
                            Treningi basenowe dla osób posiadających stopień nurkowy (tzw. "stopniówka") rozpoczynają się w połowie
                            listopada i trwają do końca maja. Zajęcia odbywają się 1 raz w tygodniu w godzinach wieczornych i trwają
                            45 min (jest to czas stricte spędzony w wodzie, dlatego na zajęciach należy pojawiać się chwilę wcześniej).
                            Konieczne jest również posiadanie swojego sprzętu ABC (czyli maski, płetwy i rurkę) od pierwszych zajęć.
                        </p>
                        <br></br>
                        <p>
                            Kurs basenowy dla osób nie posiadających jeszcze stopnia nurkowego (tzw. "zerówka") to cykl 6 basenów
                            pływackich oraz 2 nurkowania w akwalungu. W ciągu 2,5 miesiąca każdy z Was podszlifuje kondycję i technikę
                            pływania oraz opanuje nurkowanie na zatrzymanym oddechu w ABC (fajka, maska, płetwy). W ramach kursu
                            basenowego będzie okazja zanurkować w akwalungu (1 raz na basenie oraz raz na wodach otwartych). Tak dobrze
                            przygotowani przechodzicie do kolejnego etapu:{" "}
                            <a
                                href="https://akpkrab.pl/course/p1"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                kursu na podstawowy stopień nurkowy na wodach otwartych - P1 KDP/CMAS
                            </a>
                            , który otwiera przed Wami bramy podwodnego świata!
                        </p>
                        <br></br>
                        <p>
                            Jednak zajęcia basenowe to nie wszystko! Przed rozpoczęciem zajęć zaprosimy Was jeszcze na wykład pt.:
                            "Krabowe ABC", prowadzony przez wiceprezesa ds. technicznych, na którym dowiecie się, jaki sprzęt
                            wybrać.
                        </p>
                        <br></br>
                        <p>
                            Wakacyjne nurkowania zupełnie inaczej smakują po zimie dobrze przepracowanej na basenie. Lekkość, większy
                            spokój i poczucie pewności, zero zmęczenia. Jak przyjemnie się wtedy nurkuje! Każdy z nas miał okazję się
                            przekonać, jak ważne są treningi basenowe gdy z jakiegoś powodu zaniedbał hart ducha i ciała w sezonie
                            jesienno-zimowym, a wiosną trafił na wyjazd nurkowy.
                        </p>
                        <br></br>
                        <p>
                            Zimowy trening basenowy jest u nas również przygotowaniem do kursu na wodach otwartych. Zaczynając
                            przygodę w Krabie traficie do tzw. "Zerówki" – początkującej grupy basenowej. Szkoda czasu na naukę
                            pływania w płetwach i masce na kursie nurkowym na wodach otwartych - można ten czas lepiej spożytkować.
                        </p>
                        <div className="images" style={{ textAlign: 'center' }}>
                            <img
                                src="https://www.akpkrab.pl/uploads/zajecia_basenowe_2.jpg"
                                alt="Zajęcia pływackie - pływanie na zatrzymanym oddechu."
                                title="Zajęcia pływackie - pływanie na zatrzymanym oddechu."
                                style={{
                                    width: '100%',
                                    maxWidth: '600px',
                                    height: 'auto',
                                    borderRadius: '8px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                    marginTop: '20px',
                                }}
                            />
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default TrainingPool;
