import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Footer.css'; // Plik CSS dla stylów stopki

const Footer = () => {

    return (
        <footer className="footer">
            <div className="footer-content">
                <div className="footer-column">
                    <ul className="footer-links">
                        <li>
                            <Link to={"/"}>Strona Główna</Link>
                        </li>
                        <li>
                            <Link to={"/o-nas/kim-jestesmy"} onClick={() => { setTimeout(() => { window.location.reload(); }, 0); }}>O nas</Link>
                        </li>
                        <li>
                            <Link to={"/o-nas/kalendarium-krabowe"} onClick={() => { setTimeout(() => { window.location.reload(); }, 0); }}>Kalendarium Krabowe '24</Link>
                        </li>
                        <li>
                            <Link to={"/rekrutacja/kurs-p1"} onClick={() => { setTimeout(() => { window.location.reload(); }, 0); }}>Kurs P1</Link>
                        </li>
                        <li>
                            <Link to={"/rekrutacja/oboz-elba"} onClick={() => { setTimeout(() => { window.location.reload(); }, 0); }}>Obóz Szkoleniowy</Link>
                        </li>
                        <li>
                            <Link to={"/szkolenia/treningi-basenowe"} onClick={() => { setTimeout(() => { window.location.reload(); }, 0); }}>Treningi Basenowe</Link>
                        </li>
                        <li>
                            <Link to={"/cennik"}>Cennik</Link>
                        </li>
                        <li>
                            <a href={`/rezerwacja`}>Rezerwacja Sprzętu</a>
                        </li>
                    </ul>
                </div>
                <div className="footer-column">
                    <div className="footer-address">
                        <p><strong>Adres korespondencyjny:</strong><br></br>
                            AKP Krab AGH<br></br>
                            ul. Władysława Reymonta 21A<br></br>
                            30-059 Kraków</p>
                        <p><strong>Adres e-mail:</strong><br></br>
                            krab@agh.edu.pl</p>
                        <p><strong>Nr konta:&nbsp;</strong></p>
                        <p>45 1750 0009 0000 0000 3600 5588</p>
                        <p><strong>Godziny klubowe:</strong><br></br>
                            poniedzialek, środa, piątek<br></br>
                            19:00 – 21:00</p>
                        {/* <p><strong>Telefony kontaktowe:</strong><br></br>
                            &nbsp; <em><span><em>Krzysztof Sekuła&nbsp;</em>- Prezes<br></br>
                            </span></em><span>tel. kom.: (+48) 517 422 769<br></br>
                            </span></p>
                        <p><em>Tomasz Urban - Wiceprezes ds. Technicznych</em><br></br>
                            tel. kom.: (+48) 501 894 092</p> */}
                    </div>
                </div>
                <div className="footer-column">
                    <div className="footer-map">
                        <iframe
                            src="https://maps.google.com/maps?f=q&amp;source=s_q&amp;hl=pl&amp;geocode=&amp;q=akp+krab+agh&amp;aq=&amp;sll=37.0625,-95.677068&amp;sspn=59.50923,79.013672&amp;t=m&amp;ie=UTF8&amp;hq=akp+krab+agh&amp;hnear=&amp;radius=15000&amp;ll=50.066478,19.911616&amp;spn=0.077134,0.205994&amp;z=17&amp;output=embed"
                            width="600"
                            height="450"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            loading="lazy"
                            title="Map to location"
                        ></iframe>
                    </div>
                </div>
            </div>
            <div className="footer-copyright">
                <p>&copy; {new Date().getFullYear()} AKP KRAB. Wszelkie prawa zastrzeżone.</p>
            </div>
        </footer>
    );
};

export default Footer;