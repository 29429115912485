import React from "react";
import "./Pages.css";

function PriceList() {
    return (
        <div className="container">
            <div className="col-md-12">
                <header className="header-internal">
                    <h3>Cennik kursów</h3>
                </header>
                <section className="content">
                    <div className="txtBox">
                        <p>
                            Poniżej znajdziecie aktualny cennik szkoleń prowadzonych w naszym klubie. Jeśli masz
                            dodatkowe pytania dotyczące kosztów, skontaktuj się z nami.
                        </p>
                        <p>
                            <em>Kliknij nazwę kursu, aby zobaczyć jego szczegółowy opis.</em>
                        </p>
                        <br></br>

                        {renderCourseSection('Płetwonurek KDP CMAS P1', 'https://akpkrab.pl/course/p1', '4', '6', '1450 zł', '1700 zł', '-', '2700 zł')}
                        {renderCourseSection('Płetwonurek KDP CMAS P2', 'https://akpkrab.pl/course/p2', '3', '4', '1650 zł', '2000 zł', '-', '3600 zł')}
                        {renderCourseSection('Płetwonurek KDP CMAS PPI (P3)', 'https://akpkrab.pl/course/p3', '3', '4', '2000 zł', '-', '-', '3100 zł')}
                        {renderCourseSection('Płetwonurek KDP CMAS PNO', 'https://akpkrab.pl/course/pno', '4', '4', '400 zł', '550 zł', '-', '750 zł')}
                        {renderCourseSection('Płetwonurek KDP CMAS PNA', 'https://akpkrab.pl/course/pna', '4', '4', '400 zł', '500 zł', '-', '700 zł')}
                        {renderCourseSection('Płetwonurek KDP CMAS PE', 'https://akpkrab.pl/course/pe', '4', '4', '800 zł', '900 zł', '-', '1300 zł')}
                        {renderCourseSection('Płetwonurek KDP CMAS PSS', 'https://akpkrab.pl/course/pss', '2', '4', '400 zł', '500 zł', '-', '750 zł')}
                        {renderCourseSection('Płetwonurek KDP CMAS PN1', 'https://akpkrab.pl/course/pn1', '2', '4', '400 zł', '500 zł', '-', '750 zł')}
                        {renderCourseSection('Płetwonurek KDP CMAS PZB', 'https://akpkrab.pl/course/pzb', '2', '4', '700 zł', '900 zł', '-', '1000 zł')}
                        {renderCourseSection('Płetwonurek KDP CMAS PKB', 'https://akpkrab.pl/course/pkb1', '2', '4', '800 zł', '950 zł', '1000 zł', '1100 zł')}
                        {renderCourseSection('Płetwonurek KDP CMAS PN2', 'https://akpkrab.pl/course/pn2', '2', '2', '1100 zł', '1600 zł', '-', '2600 zł')}
                        {renderCourseSection('Płetwonurek KDP CMAS PN2 w konfiguracji bocznej PN2KB', 'https://akpkrab.pl/course/pn2', '2', '2', '1100 zł', '1600 zł', '-', '2600 zł')}
                        {renderCourseSection('Płetwonurek KDP CMAS PJ1', 'https://akpkrab.pl/course/pj1', '2', '2', '1000 zł', '1150 zł', '1200 zł', '1800 zł')}
                        {renderCourseSection('Płetwonurek KDP CMAS PWM (Płetwonurek Wrakowo Morski)', 'https://akpkrab.pl/course/pwm', '2', '4', '-', '-', '-', '-')}
                        {renderCourseSection('Płetwonurek KDP CMAS PPW', 'https://akpkrab.pl/course/ppw', '2', '4', '-', '-', '-', '-')}
                        {renderCourseSection('Płetwonurek KDP CMAS PPK (Płetwonurek Działań Proobronnych i Kryzysowych)', 'https://akpkrab.pl/course/ppk', '2', '3', '-', '-', '-', '-')}
                        {renderCourseSection('Płetwonurek KDP CMAS PPL (Płetwonurek Podlodowy)', 'https://akpkrab.pl/course/ppl', '2', '2', '-', '-', '-', '-')}
                        {renderCourseSection('Płetwonurek KDP CMAS PEK (płetwonurek ekolog)', 'https://akpkrab.pl/course/pek', '2', '4', '-', '700 zł', '-', '1100 zł')}
                        {renderCourseSection('Płetwonurek KDP CMAS PPI (P3i)', 'https://akpkrab.pl/course/ppi', '2', '2', '-', '750 zł', '-', '1100 zł')}
                        {renderCourseSection('Płetwonurek KDP CMAS PPP1 (Pierwsza Pomoc) - tymczasowo brak opisu', 'https://akpkrab.pl/szkolenia/opis-kursow-nurkowych', '2', '6', '-', '550 zł', '-', '-')}



                    </div>
                </section>
            </div>
        </div>
    );
}

function renderCourseSection(title, link, min, max, priceElba, price, priceBig, alone) {
    return (
        <>
            <h4>
                <a href={link} target="_blank" rel="noopener noreferrer">
                    {title}
                </a>
            </h4>
            <table className="table-bordered-sec">
                <thead>
                    <tr>
                        <th>Minimalna liczba uczestników</th>
                        <th>Maksymalna liczba uczestników</th>
                        <th>Cena kursu na Elbie</th>
                        <th>Cena kursu Weekendowego</th>
                        <th>Cena kursu Weekendowego bez opłaconej składki</th>
                        <th>Cena kursu Weekendowego indywidualnie</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td data-label="Minimalna liczba uczestników">{min}</td>
                        <td data-label="Maksymalna liczba uczestników">{max}</td>
                        <td data-label="Cena kursu na Elbie">{priceElba}</td>
                        <td data-label="Cena kursu Weekendowego">{price}</td>
                        <td data-label="Cena kursu Weekendowego bez opłaconej składki">{priceBig}</td>
                        <td data-label="Cena kursu Weekendowego indywidualnie">{alone}</td>
                    </tr>
                </tbody>
            </table>
        </>
    );
}

export default PriceList;
