import React from 'react';
import './Pages.css';

function RecruitmentPage() {
    return (
        <div className="container">
            <div className="col-md-12">
                <header className="header-internal">
                    <h3>Dołącz do nas!</h3>
                </header>
                <section className="content cmsContent">
                    <div className="txtBox">
                        <p>Zanurzasz się w błękitnej wodzie. Oddychasz. Przed sobą masz ściankę rafy pełną życia. Czerwone i kremowe ukwiały lekko falują. Razem z nimi poruszają się gromady kolorowych rybek. Jesteś wśród nich.</p>
                        <div className="images">
                            <img
                                src="https://www.akpkrab.pl/uploads/rybki.png"
                                alt="Rybki"
                                style={{
                                    width: '100%',
                                    maxWidth: '600px',
                                    height: 'auto',
                                    borderRadius: '8px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                    marginTop: '20px',
                                }}
                            />
                        </div>
                        <p>Czytając o nurkowaniu lub oglądając wspaniałe filmy spod wody na pewno w Twojej głowie rozbrzmiewały dziesiątki pytań: czy nurkowanie jest dla mnie? Kto może nurkować? Jakie wymagania trzeba spełnić, aby uzyskać odpowiednie uprawnienia? Jakie są przeciwwskazania? Z pewnością wiele odpowiedzi Cię zaskoczy. Już za kilka chwil może się okazać, że coś, co wydawało się być poza Twoim zasięgiem, jest dostępne na wyciągnięcie ręki.</p>
                        <br></br>
                        <p><strong>Zainteresowany? Zainteresowana? Zapraszamy do zapoznania się z poniższym artykułem!</strong></p>
                        <br></br>
                        <p>Jeżeli czytasz ten wpis, to znaczy że jesteś zainteresowany/-a wstąpieniem do naszego Klubu! Szalenie nam miło, że chcesz rozpocząć podwodną przygodę i móc współdzielić z nami fascynację nurkowaniem.</p>
                        <p>W tym artykule znajdziesz odpowiedzi na pytania, jak wygląda przynależność do naszego klubu, jak wygląda rozpoczęcie przygody z nurkowaniem oraz wiele wiele innych.</p>
                        <p>Na początek musisz zadać sobie pytanie, czy jesteś zainteresowany/-a tylko i wyłącznie zrobieniem kursu nurkowego? A może jednak chcesz rozwijać się również pływacko i podczas treningów basenowych poznawać elementu nurkowania i jeszcze lepiej przygotować się do samego kursu nurkowego?</p>
                        <br></br>
                        <p><strong>W Krabie mamy do zaoferowania dwie ścieżki, którymi możesz podążyć:</strong></p>
                        <br></br>
                        <p>A. (przez nas bardziej zalecana) Jeżeli dołączysz do klubu jesienią, to gorąco zachęcamy do zapisania się na baseny kondycyjne, które są świetnym wstępem do kursu nurkowego. Ideą zajęć basenowych jest przede wszystkim przygotowanie przyszłych nurków do bezpiecznego zejścia pod wodę oraz ukształtowanie w nich nawyków, mających na celu bezpieczną i partnerską eksplorację świata podwodnego. Od listopada raz w  tygodniu w godzinach wieczornych wraz ze swoją grupą pływacką spotykać się będziesz na basenie z instruktorem i pod jego czujnym okiem poprawisz swoją kondycję, podszkolisz techniki pływackie oraz będziesz mieć okazję pływania w ABC, tj. masce, fajce i płetwach. To właśnie na zajęciach basenowych będziesz mieć wprowadzane elementy nurkowania na wstrzymanym oddechu, które są doskonałym preludium do późniejszego kursu nurkowego na wodach otwartych. W ramach zajęć basenowych zanurkujesz ze sprzętem na basenie (oczywiście w towarzystwie naszego instruktora) oraz raz w ramach akcji klubowej w maju pojedziemy na otwarty akwen, gdzie porzucisz grawitację i zanurkujesz w akwalungu. Po tak zrealizowanym półrocznym treningu basenowym będziesz w pełni gotowy/-a do wzięcia udziału w kursie na podstawowy stopień nurkowy na wodach otwartych - P1 KDP/CMAS. O sekcji basenowej nieco więcej napisaliśmy <a href="https://www.akpkrab.pl/szkolenia/treningi-basenowe" target="_blank" rel="noreferrer">TUTAJ</a>.</p>
                        <br></br>
                        <p>B. Jeżeli z jakiegoś powodu nie możesz sobie pozwolić na zapisanie się do naszej sekcji pływackiej, ale chcesz zrobić sam kurs nurkowy, to mamy Tobie do zaoferowania następujące scenariusze: w naszym Klubie kursy realizujemy na dwa sposoby: w trybie weekendowym (wówczas musisz sobie zarezerwować dwa weekendy pod rząd) lub też podczas naszego Morskiego Obozu Szkoleniowego na Elbie we Włoszech. Szczegółowe opisy kursów weekendowych i w systemie obozowym znajdziesz pod tymi linkami: <a href="https://www.akpkrab.pl/rekrutacja/kurs-p1" target="_blank" rel="noreferrer">TUTAJ</a> oraz <a href="https://www.akpkrab.pl/rekrutacja/oboz-elba" target="_blank" rel="noreferrer">TUTAJ</a>.</p>
                        <br></br>
                        <p>W trybie weekendowym szkolimy od czerwca do października - jest to uzależnione od warunków atmosferycznych oraz temperatury wody w akwenie. Musisz mieć na uwadze, że woda w polskich jeziorach po zimie nie należy do najcieplejszych, stąd sezon szkoleniowy otwieramy dopiero w czerwcu. Z kolei Morski Obóz Szkoleniowy odbywa się na przełomie maja i czerwca i trwa 12 dni. Szczegółowe opisy znajdziesz pod ww. linkami. </p>
                        <p>Musimy tutaj zaznaczyć, że treningi basenowe są uzupełnieniem i przygotowaniem do kursu, a nie jego substytutem, nie bój się rozpocząć przygody nurkowej bez zajęć basenowych.</p>
                        <br></br>
                        <div className="images">
                            <img
                                src="https://www.akpkrab.pl/uploads/woda.png"
                                alt="Woda"
                                style={{
                                    width: '100%',
                                    maxWidth: '600px',
                                    height: 'auto',
                                    borderRadius: '8px',
                                    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                    marginTop: '20px',
                                }}
                            />
                        </div>
                        <br></br>
                        <h4>Niewykluczone, że masz jeszcze inne pytania dotyczące samego nurkowania. Poniżej przedstawiamy krótkie FAQ. Wierzymy, że po zapoznaniu się z poniższymi odpowiedziami już beż żadnego stresu zdecydujesz się na piękną przygodę, jaką jest eksploracja podwodnego świata razem z AKP Krab AGH!</h4>
                        <br></br>
                        <p><strong>Czy muszę mieć zgodę lekarza, aby nurkować?</strong></p>
                        <br></br>
                        <p>Niekoniecznie. Federacje nurkowe co prawda pozwalają na rozpoczęcie kursu nurkowego na oświadczenie kursanta o dobrym stanie zdrowia, niemniej jednak naszemu Klubowi przyświeca motto: Jakość i bezpieczeństwo, dlatego zachęcamy do sprawdzenia swojego stanu zdrowia i wykonania następujących badań lekarskich: morfologia, badanie moczu, RTG klatki piersiowej, EKG serca, opinii laryngologicznej. Na ich podstawie lekarz wyda zaświadczenie lekarskie o  braku przeciwwskazań do nurkowania.</p>
                        <p>Z nurkowania wykluczyć Cię mogą poważne zaburzenia układu oddechowego i krążeniowego oraz niedawno przebyte urazy, ale ostateczną decyzje i tak podejmuje lekarz, dlatego warto zasięgnąć jego opinii.</p>
                        <br></br>
                        <p><strong>Czy nurkowanie jest bezpieczne?</strong></p>
                        <br></br>
                        <p>Nurkowanie na poziomie rekreacyjnym uznawane jest za bardzo bezpieczny sport. Dopóki przestrzega się zasad bezpiecznego i odpowiedzialnego nurkowania, którego podstawą jest partnerska asekuracja i dbałość o sprzęt, prawdopodobieństwo wypadku pod wodą jest minimalne.</p>
                        <p>Na przestrzeni wielu lat powstał zbiór dobrych praktyk nurkowych, który w sposób szczegółowy określa konfigurację sprzętu, organizację nurkowania oraz zachowania pod wodą mające wykluczyć sytuacje potencjalnie niebezpieczne, a jeżeli takowe wystąpią - uczy odpowiedniej reakcji. Analiza wypadków nurkowych z ostatnich dziesięcioleci wykazała, że przyczyną większości z nich było nieprzestrzeganie powyższych zasad.</p>
                        <br></br>
                        <p><strong>Czy dzieci mogą nurkować?</strong></p>
                        <br></br>
                        <p>Tak. Niezbędna jest zgoda opiekunów oraz zaświadczenie lekarskie o braku przeciwwskazań do nurkowania. Spełniając te kryteria, pod wodę wraz z instruktorem nurkowania na głębokość 2 m może zejść już 8-latek uzyskując np. stopień Płetwonurka Młodzieżowego Brązowego. Wraz z wiekiem, dzieci mogą rozwijać uprawnienia nurkowe, np. 12-latek może nurkować z instruktorem lub opiekunem posiadającym stopień nurkowy do 12 m.</p>
                        <br></br>
                        <p><strong>Czy potrzebuje certyfikatu, aby zejść pod wodę?</strong></p>
                        <br></br>
                        <p>Certyfikat jest niejako potwierdzeniem Twoich kompetencji nurkowych. Ukończenie kursu pozwala nabyć umiejętności praktyczne oraz wiedzę teoretyczną, które gwarantują bezpieczne nurkowanie. Nurkowanie bez ukończonego kursu jest zachowaniem skrajnie nieodpowiedzialnym, a w przypadku nurkowania z drugą osobą, niesie za sobą konsekwencje prawne. Poza określonymi sytuacjami takimi jak intro nurkowe (czyli nurkowanie zapoznawcze jeden na jeden z instruktorem) absolutnie nie popieramy nurkowania bez uprzednio uzyskania odpowiednich kwalifikacji.</p>
                        <br></br>
                        <p><strong>Czy nie umiejąc pływać, mogę rozpocząć naukę nurkowania?</strong></p>
                        <br></br>
                        <p>Umiejętność pływania podczas nurkowania jest przydatna. Nie wymaga się od kursanta umiejętności pływania w stopniu zaawansowanym, jednak ważnym jest, aby osoba rozpoczynająca przygodę z nurkowaniem nie odczuwała lęku przed wodą. Jeżeli jednak nie posiadasz umiejętności pływackich nawet w stopniu podstawowym, nic straconego. Nasz Klub prowadzi przygotowawcze baseny, w ramach których zapisać się możesz do sekcji nauki pływania, pozwalającej na obycie się z wodą i pozbycie lęków przed zanurzeniem.</p>
                        <br></br>
                        <p><strong>Niepełnosprawność a nurkowanie?</strong></p>
                        <br></br>
                        <p>Jeżeli zastanawia Cię, czy osoba niepełnosprawna może rozpocząć przygodę z nurkowaniem, to odpowiadamy: tak, jak najbardziej. Ograniczenia fizyczne takie jak: urazy rdzenia kręgowego, porażenie mózgowe, uszkodzenia narządów wzroku i słuchu lub inne dysfunkcje fizyczne nie wykluczają osoby z uzyskania stopnia nurkowego. Warunkiem rozpoczęcia kursu jest determinacja, brak lęku przed wodą oraz orzeczenie lekarskie o braku przeciwwskazań do nurkowania. Kurs taki jest wówczas prowadzony przez wykwalifikowaną kadrę instruktorską i przeprowadzany jest na basenie oraz wodach otwartych. Istnieją Organizacje Nurkowe, które specjalizują się w pracy z osobami niepełnosprawnymi oraz dysponują odpowiednim sprzętem ułatwiającym odbycie całego cyklu szkoleniowego. W tym miejscu nie możemy nie wspomnieć o Stowarzyszeniu Nautica, z którego działalnością warto się zapoznać: http://stowarzyszenienautica.pl/</p>
                        <br></br>
                        <p><strong>Czy chcąc zapisać się na kurs, muszę mieć swój sprzęt?</strong></p>
                        <br></br>
                        <p>Posiadanie własnego sprzętu nie jest konieczne. AKP Krab AGH w cenie kursu oferuje wypożyczenie sprzętu, takiego jak chociażby jacket czy pianki nurkowe. Coraz częściej kursantom oferuje się bardziej ergonomiczne skafandry półsuche, które zapewniają zdecydowanie lepszą termikę. Zaleca się jednak zakup najbardziej osobistego sprzętu nurkowego, tzw. ABC nurkowego (maska, fajka, płetwy) ze względów higienicznych i praktycznych. Źle dobrana maska może przeciekać, dlatego też warto zaopatrzyć się w swój prywatny zestaw. W dobraniu ABC pomogą Ci specjaliści z naszego klubowego Magazynu oraz sklepu sportowego, w którym dokonasz zakupu. Nie bój się zapytać.</p>
                        <br></br>
                        <p><strong>Co to jest freediving? Czy nurkowanie zawsze wiąże się z zabraniem sprzętu pod wodę?</strong></p>
                        <br></br>
                        <p>Freediving jest to nurkowanie na zatrzymanym oddechu. Freedivier, wyposażony jedynie w ABC oraz balast, schodzi pod wodę bez akwalungu. Istnieją dwie zasadnicze odmiany freedivingu: nurkowanie w głąb praktykowane na zbiornikach otwartych oraz nurkowanie basenowe będące sportową odmianą freedivingu: polega ono na dynamicznym przepłynięciu basenu bez wynurzania oraz statycznym wstrzymaniu oddechu. Tak jak w przypadku nurkowania sprzętowego, freediving jest sportem partnerskim i bez odpowiedniej asekuracji z powierzchni oraz w wodzie, bezwzględnie zabrania się uprawiania tego sportu w pojedynkę.</p>
                        <p>Aby móc w sposób bezpieczny uprawiać freediving, należy również odbyć w tym celu odpowiedni kurs nurkowy zakończony egzaminem teoretycznym i praktycznym. Na pewno zadziwi Cię fakt, iż część kursu freedivingowego prowdzona jest na powierzchni - “na sucho” na macie, gdzie poznasz techniki oddechowe i relaksacyjne niezbędne do długiego zejścia pod wodę.</p>
                        <p>Nie zaskoczy Cię chyba fakt, że w naszej ofercie posiadamy sekcję basenową freedivingową, a w sezonie letnim realizujemy kursy nurkowe.</p>
                        <br></br>
                        <p><strong>Co po kursie?</strong></p>
                        <br></br>
                        <p>Kurs na płetwonurka rekreacyjnego P1 KDP/CMAS otwiera przed Tobą szeroki wachlarz możliwości. Możesz, wraz z płetwonurkami o takich samych lub wyższych uprawnieniach, eksplorować podwodny świat na głębokość do 20 m. Uzyskanie stopnia nurkowego pozwala również na rozwijanie się w tej dziedzinie i poszerzanie swoich umiejętności o kolejne stopnie, np. Płetwonurek Nocny bądź Nawigator, a także zdobywanie kolejnych stopni nurkowych uprawniających Cię do nurkowania na większe głębokości i w innych konfiguracjach sprzętowych.</p>
                        <p>Certyfikat w kieszeni otwiera również przed Tobą możliwość wyjazdów na wspaniałe safari nurkowe, które bez odpowiednich uprawnień są poza naszym zasięgiem. </p>
                        <p>Ponadto, krakowski AKP Krab AGH prowadzi zajęcia basenowe dla certyfikowanych nurków, którzy w okresie zimowym pragną pozostać w dobrej formie nurkowej. Nazywamy to basenami stopniowymi, gdyż są dedykowane dla osób posiadających stopień nurkowy.</p>
                        <br></br>
                        <p><strong>Jak dbać o podstawowy sprzęt nurkowy?</strong></p>
                        <br></br>
                        <p>W zależności od rodzaju sprzętu, z jakiego korzystasz oraz warunków, w jakich nurkujesz, konserwacja sprzętu nurkowego będzie się między sobą nieco różnić. Przede wszystkim należy pamiętać o bezpiecznym transporcie sprzętu. Automaty oraz ABC powinny być zabezpieczone i przewożone w odpowiednich futerałach, które zapewnią ochronę przed połamaniem oraz awariami. Skafandry nurkowe również muszą być zabezpieczone w taki sposób, aby suwaki nie zostały zagięte i nie ulegały długotrwałym naprężeniom, które mogą skutkować w późniejszym czasie ich pęknięciem. Jeżeli jesteś na wyjeździe nurkowym, gdzie eksplorujesz podwodny świat w morskiej wodzie, ważnym jest, aby po każdym nurkowaniu dokładnie wypłukać sprzęt w słodkiej wodzie. Dotyczy to nie tylko pianek i skafandrów, ale także automatów oddechowych i jacketów. Niestety sprzęt niedokładnie wypłukany z morskiej wody, może korodować, a w układzie może się wytrącać sól. Do jacketu należy poprzez inflator wprowadzić nieco słodkiej wody i poprzez wstrząsy wypłukać słoną wodę. Trzeba zminimalizować czas między nurkowaniem a płukaniem. Zmniejszy to ryzyko jakichkolwiek awarii z tytułu wytrącania się soli.</p>
                        <p>Mniejsze ryzyko korozji sprzętu niesie nurkowanie w słodkich zbiornikach, niemniej jednak nadal ważne jest przepłukanie sprzętu w czystej wodzie, co zapobiega rozwojowi pleśni i bakterii.</p>
                        <p>Aby maska nie zaparowywała nam pod wodą, ważne jest jej regularne odtłuszczanie. W sklepach nurkowych można nabyć oczywiście specjalne preparaty do tego, ale prościej będzie po prostu wykorzystać płyn do naczyń i dokładnie umyć maskę.</p>
                        <p>Suwaki? Tak, tak. One również wymagają konserwacji. Jeżeli w skafandrze mamy suwaki metalowe, najprostszym i najtańszym sposobem ich woskowania jest wykorzystanie… świecy. Początek suwaka należy potrzeć woskiem, a potem kilkakrotnie zasunąć i rozsunąć zamek aby rozprowadzić wosk na całej jego długości. A co w przypadku posiadania pianki z suwakiem plastikowym? Tutaj musimy już się posiłkować odpowiednim preparatem, który znajdziesz w sklepach sportowych bądź wykorzystać smar silikonowy.</p>
                        <p>Na pewno wydaje Ci się, że tych informacji jest wiele, ale koledzy z Klubu zawsze służą pomocą i z chęcią podzielą się swoją wiedzą. Jeżeli już od pierwszych nurkowań będziesz przestrzegać kilku podstawowych zasad, konserwacja sprzętu wejdzie Ci w nawyk i na długi czas zabezpieczysz swój ekwipunek przed korozyjnym działaniem środowiska wodnego.</p>
                        <br></br>
                        <br></br>
                        <p>Jak sam widzisz, nurkowanie, które jeszcze kilka chwil temu wydawało się być poza zasięgiem Twoich możliwości, jest dostępne już dziś. Nie tylko kurorty nadmorskie oferują naukę nurkowania. Wystarczy nieco zgłębić temat, aby dowiedzieć się, że praktycznie w każdym większym mieście są kluby i szkółki nurkowe, które przygotują Cię do bezpiecznego zejścia pod wodę. Ponadto należy pamiętać, że nie trzeba czekać do wiosny lub lata, aby rozpocząć przygotowania do kursu nurkowego, ponieważ zajęcia basenowe trwają właśnie w okresie jesienno-zimowym. Zaciekawiony/-a? Jeżeli tak, przyjdź do Kraba w poniedziałek-środę- piątek w godz. 18:00-21:00 i razem z innymi nurkami, pozbądź się grawitacji!</p>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default RecruitmentPage;
