import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './NewsList.css';

const NewsList = () => {
    const [newsList, setNewsList] = useState([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useState(null);
    const [itemsPerPage, setItemsPerPage] = useState(10); // Domyślna liczba newsów na stronę
    const [currentPage, setCurrentPage] = useState(1); // Aktualna strona

    useEffect(() => {
        // Pobierz dane podczas montowania komponentu
        fetch("https://akpkrab.pl:443/newsItems", {
            method: 'GET',
            headers: {
                'Authorization': 'a9b844871969b44bb5bb1845ca0e6b68669d544cab184422e76265e6f06b8068'
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setNewsList(result);
                    setIsLoaded(true);
                },
                (error) => {
                    setIsLoaded(true);
                    setError(error);
                }
            );
    }, []);

    const handleItemsPerPageChange = (count) => {
        setItemsPerPage(count);
        setCurrentPage(1); // Reset do pierwszej strony
    };

    const totalPages = Math.ceil(newsList.length / itemsPerPage); // Oblicz liczbę stron
    const paginatedNews = newsList.slice()
        .reverse()
        .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage); // News na bieżącą stronę

    if (error) {
        return <div>Error: {error.message}</div>;
    } else if (!isLoaded) {
        return <div>Loading...</div>;
    } else {
        return (
            <div id="news-section" className="news-list">
                {/* Renderuj newsy */}
                {paginatedNews.map((news, index) => {
                    const reversedIndex = newsList.length - 1 - index;
                    if (news.type === "active") {
                        return (
                            <div key={index} className={`news-item ${index % 2 === 0 ? 'left' : 'right'}`}>
                                <div className="news-image">
                                    <img src={"https://www.akpkrab.pl/uploads/" + news.image} alt={news.title} />
                                </div>
                                <div className="news-content">
                                    <h3 className="news-content-h3">{news.title}</h3>
                                    <p dangerouslySetInnerHTML={{ __html: news.short }}></p>
                                    <Link onClick={() => {
                                        setTimeout(() => { setTimeout(() => { window.location.reload(); }, 0);; }, 0);
                                    }} to={`/news/${reversedIndex}`}>Szczegóły</Link>
                                </div>
                            </div>
                        );
                    }
                })}

                {/* Przyciski wyboru liczby newsów */}
                <div className="pagination-controls">
                    <div className="items-per-page">
                        <p className="n">Aktualności na stronie :</p>
                        {[10, 20, 30].map((count) => (
                            <button
                                key={count}
                                onClick={() => handleItemsPerPageChange(count)}
                                className={count === itemsPerPage ? 'active' : ''}
                            >
                                {count}
                            </button>
                        ))}
                    </div>

                    {/* Paginacja */}
                    <div className="pagination">
                        <p className="n">Strona :</p>
                        {[...Array(totalPages)].map((_, index) => (
                            <button
                                key={index}
                                onClick={() => setCurrentPage(index + 1)}
                                className={currentPage === index + 1 ? 'active' : ''}
                            >
                                {index + 1}
                            </button>
                        ))}
                    </div>
                </div>
            </div>
        );
    }
};

export default NewsList;
