// src/App.js

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import SliderComponent from './components/SliderComponent';
import NewsList from './components/NewsList';
import NewsDetail from './components/NewsDetail';
import SliderDetail from './components/SliderDetail';
import StickyMenu from './components/StickyMenu';
import Footer from './components/Footer';
import DynamicPage from './components/DynamicPage';

import WeWhoWeAre from './components/pages/WeWhoWeAre';
import WeHistory from './components/pages/WeHistory';
import WeIns from './components/pages/WeIns';
import WeStatute from './components/pages/WeStatute';
import WeManagementAndCouncil from './components/pages/WeManagementAndCouncil';
import WeClubDoctors from './components/pages/WeClubDoctors';
import WeCalendar24 from './components/pages/WeCalendar24';
import RecrutationCourseP1 from './components/pages/RecrutationCourseP1';
import RecrutationCampElba from './components/pages/RecrutationCampElba';
import TrainingInKrab from './components/pages/TrainingInKrab';
import TrainingPool from './components/pages/TrainingPool';
import TrainingPoolSchedule from './components/pages/TrainingPoolSchedule';
import TrainingDivingCourseSchedule from './components/pages/TrainingDivingCourseSchedule';
import TrainingFreedivingSection from './components/pages/TrainingFreedivingSection';
import PriceList from './components/pages/PriceList';
import TrainingCoursesTable from './components/pages/TrainingCoursesTable';
import CoursePage from './components/pages/CoursePage';
import RecruitmentPage from './components/pages/RecruitmentPage';

import './App.css';

function App() {
    const [pagesData, setPageItems] = useState([]);

    useEffect(() => {
        // Fetch danych na starcie komponentu
        fetch("https://akpkrab.pl:443/pageItems", {
            method: 'GET',
            headers: {
                'Authorization': 'a9b844871969b44bb5bb1845ca0e6b68669d544cab184422e76265e6f06b8068'
            }
        })
            .then(res => res.json())
            .then(
                (result) => {
                    setPageItems(result); // Ustawienie sliderItems po fetchu
                }
            );
    }, []);

    return (
        <Router>
            <div className="app">
                <StickyMenu />
                <Routes>
                    <Route path="/" element={<><SliderComponent /><NewsList /></>} />
                    {pagesData.map((page) => (
                        <Route key={page.id} path={"/" + page.link} element={<DynamicPage />} />
                    ))}

                    <Route path="/o-nas" element={<><SliderComponent /><NewsList /></>} />
                    <Route path="/o-nas/kim-jestesmy" element={<WeWhoWeAre />} />
                    <Route path="/o-nas/historia-kraba" element={<WeHistory />} />
                    <Route path="/o-nas/instruktorzy" element={<WeIns />} />
                    <Route path="/o-nas/statut-klubu" element={<WeStatute />} />
                    <Route path="/o-nas/zarzad-i-rada-klubu" element={<WeManagementAndCouncil />} />
                    <Route path="/o-nas/lekarze-klubowi" element={<WeClubDoctors />} />
                    <Route path="/o-nas/kalendarium-krabowe" element={<WeCalendar24 />} />
                    <Route path="rekrutacja/" element={<><SliderComponent /><NewsList /></>} />
                    <Route path="/rekrutacja/dolacz-do-nas" element={<RecruitmentPage />} />
                    <Route path="/rekrutacja/kurs-p1" element={<RecrutationCourseP1 />} />
                    <Route path="/rekrutacja/oboz-elba" element={<RecrutationCampElba />} />
                    <Route path="szkolenia/" element={<><SliderComponent /><NewsList /></>} />
                    <Route path="/szkolenia/jak-szkolimy-w-krabie" element={<TrainingInKrab />} />
                    <Route path="/szkolenia/treningi-basenowe" element={<TrainingPool />} />
                    <Route path="/szkolenia/harmonogram-treningow-basenowych" element={<TrainingPoolSchedule />} />
                    <Route path="/szkolenia/opis-kursow-nurkowych" element={<TrainingCoursesTable />} />
                    <Route path="/szkolenia/harmonogram-kursow-nurkowych" element={<TrainingDivingCourseSchedule />} />
                    <Route path="/szkolenia/sekcja-freedivingu" element={<TrainingFreedivingSection />} />
                    <Route path="/cennik" element={<PriceList />} />

                    <Route path="/course/:courseId" element={<CoursePage />} />
                    <Route path="/news/:id" element={<NewsDetail />} />
                    <Route path="/slider/:id" element={<SliderDetail />} />
                    {/* <Route path="/*" element={<Navigate to="/" replace />} /> */}
                </Routes>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
